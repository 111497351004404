<template>
  <div>{{ comment.content }}</div>
  <small-banner
    color="red"
    icon="hand paper icon red"
    :header="errorStringHeader"
    :content="errorStringHeader"
  />
</template>

<script>
import SmallBanner from "@/components/basic/errorHandling/smallBanner";
export default {
  name: "Comment",
  components: {SmallBanner},
  inject: ['socket', 'nodeID', 'sessionID', 'user', 'projectURL'],
  props: ['originComment'],
  data() {
    return {
      comment: this.originComment,
      errorString: '',
      errorStringHeader: '',
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    deleteComment(){
      this.socket.emit('deleteComment', { room: this.nodeID, uname: this.user.username, commentID: this.comment.id, postID: this.comment.postID })
    },
    likeComment(type){
      const fD = new FormData()
      fD.append('commentid', this.comment.id)
      fD.append('type', type)
      fD.append('SessionID', this.sessionID)
      this.$axios.post(this.projectURL + 'wall/newCommentLike', fD)
          .then((response) => {
            switch(response.status){
              case 250:

                break

              case 252:
              case 255:
                this.errorStringHeader = 'Fehler'
                this.errorString = 'Leider ging hier etwas schief.'
                break

              case 253:
                this.errorStringHeader = 'Geblockt'
                this.errorString = 'Der User des Posts blockt dich leider.'
                break

              case 299:
                //emit cookielogin
                break
            }
          })
          .catch((e) => {
            console.log('likeComment', e)
            this.errorStringHeader = 'Fehler'
            this.errorString = 'Leider ging hier etwas schief.'
          })
    }
  }
}
</script>

<style scoped>

</style>