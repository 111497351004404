import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {chatStore} from "@/store/chatStore";

import Login from '@/views/login.vue'
import adminNavBar from '@/views/basic/adminNavBar'
import publicNavBar from '@/views/basic/publicNavBar'
import start from '@/views/start'


import publicFooter from '@/views/basic/publicFooter'

import home from '@/views/home'

import profile from '@/views/profile'

/*
import {closeSideMenu} from "../../alt/modules/preRoute";
import redirect from '@/views/redirect'

import settings from '@/views/settings'
import matchNavBar from '@/views/basic/matchNavBar'
import profile from '@/views/profile'

import notification from '@/views/notification'

import visitors from '@/views/visitors'

import post from '@/views/post'

import matchgame from '@/views/matchgame'

import search from '@/views/search'

import forum from '@/views/forum'
import forumOverview from '@/views/forum/overview'
import forumSearch from '@/views/forum/search'
import forumQuestion from '@/views/forum/question'
import forumAsk from '@/views/forum/ask'

import editProfile from '@/views/editProfile'
import epMenu from '@/components/editProfile/menu'
import epInfo from '@/components/editProfile/info'
import epLove from '@/components/editProfile/love'
import epLocation from '@/components/editProfile/location'
import epInterest from '@/components/editProfile/interests'
import epSocial from '@/components/editProfile/socialMedia'
import epQuestionnaire from '@/components/editProfile/questionnaire'

import messages from '@/views/messages'

import startBlog from '@/views/blog/start'
import searchBlog from '@/views/blog/search'
import articleBlog from '@/views/blog/article'
import blog from '@/views/blog'

import advancedRegister from '@/views/advancedregister'

import impressum from '@/views/website/impressum'
import eltern from '@/views/website/eltern'
import about from '@/views/website/about'
import datenschutz from '@/views/website/datenschutz'
import faq from '@/views/website/faq'
import features from '@/views/website/features'
import kontakt from '@/views/website/kontakt'
import nutzungsbedingungen from '@/views/website/nutzungsbedingungen'
import notFound from '@/views/website/404'

import test from "@/views/test";
*/

const routes = [
  {
    path: '/',
    name: 'Website',
    components: {
      navBar: publicNavBar,
      default: home,
      footer: publicFooter
    },
    meta: { noAuth: true },
    beforeEnter: (to, from, next) => {
      if (process.env.CORDOVA_PLATFORM !== undefined) {
        next('/login')
      } else {
        next()
      }
    }
  },
  {
    path: '/home',
    name: 'Home',
    components: {
      navBar: publicNavBar,
      default: home,
      footer: publicFooter
    }
  },
  {
    path: '/login/:subpage?',
    name: 'Login',
    components: {
      default: Login
    },
    props: {
      default: true
    },
    meta: { noAuth: true }
  },/*
  {
    path: '/advancedregister/:page?',
    name: 'Advancedregister',
    components: {
      default: advancedRegister
    }
  },
  {
    path: '/register/email',
    name: 'register',
    beforeEnter: (to, from, next) => {
      axios.get(window.projectURL + '/register/email?' + to.query.code)
      next('/wall')
    }
  },*/
  {
    path: '/wall',
    name: 'Wall',
    alias: '/dashboard',
    components: {
      navBar: adminNavBar,
      default: start
    },
    meta: { auth: true }
  },
  {
    path: '/p/:username',
    name: 'Profile',
    alias: '/profile/:username',
    components: {
      navBar: adminNavBar,
      default: profile
    },
    meta: { auth: true }
  },/*
  {
    path: '/notifications/:friends?',
    name: 'Notifications',
    alias: ['/notification/:friends?', '/n/:friends?'],
    components: {
      navBar: adminNavBar,
      default: notification
    },
    meta: { auth: true }
  },
  {
    path: '/v',
    name: 'Visitors',
    alias: ['/visitors'],
    components: {
      navBar: adminNavBar,
      default: visitors
    },
    meta: { auth: true }
  },
  {
    path: '/post/:post/:comment?',
    name: 'Post',
    components: {
      navBar: adminNavBar,
      default: post
    },
    props: {
      default: true
    },
    meta: { auth: true }
  },
  {
    path: '/matchgame',
    name: 'Match',
    alias: ['/match'],
    components: {
      navBar: matchNavBar,
      default: matchgame
    },
    meta: { auth: true }
  },
  {
    path: '/search',
    name: 'Search',
    components: {
      navBar: adminNavBar,
      default: search
    },
    meta: { auth: true }
  },
  {
    path: '/messages/:username?',
    name: 'Messages',
    alias: ['/chat'],
    components: {
      navBar: adminNavBar,
      default: start
    },
    meta: { auth: true },
    beforeEnter: (to, from, next) => {
      window.wurstsalat.chatModal = true
      window.wurstsalat.loading = false
      $('.scrollToTop').hide()
      $('body').addClass('no-scroll')
      if (from.name === undefined) {
        next()
      } else {
        next(false)
      }
      setTimeout(function () {
        window.chat.directChat(to.params.username)
      }, 1000)
    }
  },
  {
    path: '/call',
    name: 'Call',
    beforeEnter: (to, from, next) => {
      window.wurstsalat.callDetails = to.params
      $('.scrollToTop').hide()
      window.wurstsalat.callModal = true
      next(false)
    }
  },
  {
    path: '/blog/',
    name: 'Blog',
    components: {
      navBar: adminNavBar,
      default: blog
    },
    children: [
      {
        path: '',
        component: startBlog
      },
      {
        path: 'a/:id',
        component: articleBlog
      },
      {
        path: 's/:query?',
        component: searchBlog
      }
    ]
  },
  {
    path: '/magazin/',
    name: 'Magazin',
    components: {
      navBar: publicNavBar,
      default: blog,
      footer: publicFooter
    },
    children: [
      {
        path: '',
        component: startBlog
      },
      {
        path: 'a/:id',
        component: articleBlog
      },
      {
        path: 's/:query?',
        component: searchBlog
      }
    ]
  },
  {
    path: '/ep/',
    alias: '/editProfile/',
    name: 'EditProfile',
    components: {
      navBar: adminNavBar,
      default: editProfile
    },
    children: [
      {
        path: '',
        component: epMenu
      },
      {
        path: 'info',
        component: epInfo
      },
      {
        path: 'love',
        component: epLove
      },
      {
        path: 'location',
        component: epLocation
      },
      {
        path: 'interests',
        component: epInterest
      },
      {
        path: 'social',
        component: epSocial
      },
      {
        path: 'questionnaire',
        component: epQuestionnaire
      }
    ]
  },
  {
    path: '/forum/',
    name: 'Forum',
    components: {
      navBar: adminNavBar,
      default: forum
    },
    children: [
      {
        path: 's/:search',
        components: {
          default: forumSearch
        },
        props: {
          default: true
        }
      },
      {
        path: 'q/:questionid',
        components: {
          default: forumQuestion
        },
        props: {
          default: true
        }
      },
      {
        path: 'c/:category?',
        alias: '',
        components: {
          default: forumOverview
        },
        props: {
          default: true
        }
      },
      {
        path: 'ask',
        components: {
          default: forumAsk
        },
        props: {
          default: true
        }
      }
    ]
  },
  {
    path: '/settings',
    name: 'Einstellungen',
    components: {
      navBar: adminNavBar,
      default: settings
    },
    meta: { auth: true }
  },
  {
    path: '/redirect/',
    name: 'Redirect',
    components: {
      default: redirect
    }
  },
  {
    path: '/impressum',
    name: 'Impressum',
    components: {
      navBar: publicNavBar,
      default: impressum,
      footer: publicFooter
    }
  },
  {
    path: '/eltern',
    name: 'Eltern',
    components: {
      navBar: publicNavBar,
      default: eltern,
      footer: publicFooter
    }
  },
  {
    path: '/about',
    name: 'Über Wurstsalat',
    components: {
      navBar: publicNavBar,
      default: about,
      footer: publicFooter
    }
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    components: {
      navBar: publicNavBar,
      default: datenschutz,
      footer: publicFooter
    }
  },
  {
    path: '/faq',
    name: 'Faq',
    components: {
      navBar: publicNavBar,
      default: faq,
      footer: publicFooter
    }
  },
  {
    path: '/features',
    name: 'Features',
    components: {
      navBar: publicNavBar,
      default: features,
      footer: publicFooter
    }
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    components: {
      navBar: publicNavBar,
      default: kontakt,
      footer: publicFooter
    }
  },
  {
    path: '/nutzungsbedingungen',
    name: 'Nutzungsbedingungen',
    components: {
      navBar: publicNavBar,
      default: nutzungsbedingungen,
      footer: publicFooter
    }
  },
  {
    path: '/404',
    name: '404',
    components: {
      navBar: publicNavBar,
      default: () => import('@/views/website/404'),
      footer: publicFooter
    }
  },
  {
    path: '/teamdashboard',
    name: 'Teamdashboard',
    beforeEnter () {
      location.href = '/teamdashboard'
      next(false)
    }
  },
  {
    path: '/redaktion',
    name: 'Redaktion',
    beforeEnter () {
      location.href = '/redaktion'
      next(false)
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    components: {
      navBar: publicNavBar,
      default: notFound,
      footer: publicFooter
    }
  },
  {
    path: '/test',
    name: 'Test',
    components: {
      default: test
    },
    meta: { auth: true }
  }*/
]

const router = createRouter({
  history: (process.env.CORDOVA_PLATFORM === undefined) ? createWebHistory(process.env.BASE_URL) : createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = authStore()
  const config = configStore()
  const chat = chatStore()

  config.sidebar = false
  // prevent back from chat.
  if (to.path.startsWith('/messages')) {
    chat.force = true
    next('/wall')
  }

  window.scrollTo(0, 0)
  window.onscroll = function () {}
  $('.dropdown').dropdown()

  if(chat.force){
    chat.visible = true
    chat.force = false
  }else{
    chat.visible = false
  }

  if (to.matched.some(record => record.meta.auth)) {
    auth.update()
        .then(function (resolved) {
          config.loading = true
          if (resolved === 220) {
            next('/advancedregister')
          } else {
            next()
          }
        })
        .catch(function (e) {
          console.log(e)
          next({
            path: '/login',
            query: { redirect: to.fullPath }
          })
        })
  } else if (to.matched.some(record => record.meta.noAuth)) {
    auth.update().finally(function () {
      if (auth.isAuth) {
        config.loading = true
        next({
          path: '/wall'
        })
      } else {
        config.loading = true
        next()
      }
    })
  } else {
    auth.update()
    config.loading = true
    next()
  }
})

window.router = router

export default router
