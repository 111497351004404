<template>
  <div class="wallPost">
    <div class="wallPostHead">
      <div class="grid-12">
        <div class="col-sp-8 row-sp-12">
          <img class="profileImg" :src="config.projectURL + 'assets/images/user/' + post.profile_img">
          <div class="profileImgRightContent">
            <div class="userName">
              @{{ post.username }}
            </div>
            <div class="city">
              {{ post.cname }} ({{ post.distance }}km)
            </div>
          </div>
        </div>
        <div class="col-sp-4 row-sp-12">
          <div class="time">
            {{ config.calculateTimeString(timeFormated) }} <i class="material-icons">more_vert</i>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <p>
      {{ post.content }}
      </p>
    </div>
    <small-banner
      color="red"
      icon="hand paper icon red"
      :header="errorStringHeader"
      :content="errorStringHeader"
    />
    <div class="comments">
      <div class="comment" v-for="(comment, key, index) in post.comments" :key="comment.id">
        <comment v-if="index < visibleCommentsCount" :origin-comment="comment"/>
      </div>
    </div>
  </div>
</template>

<script>
import Comment from "@/components/wall/comment";
import SmallBanner from "@/components/basic/errorHandling/smallBanner";
import {socketStore} from "@/store/socketStore";
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";

export default {
  name: "Post",
  components: {SmallBanner, Comment},
  props: ['post'],
  setup(){
    const auth = authStore()
    const config = configStore()
    const socket = socketStore()

    return {auth, config, socket}
  },
  data() {
    return {
      timeFormated: Date.parse(this.post.timestamp),
      visibleCommentsCount: 5, //number of visible comments
      commentsAvailable: false, //bool if there are more to load
      errorString: '',
      errorStringHeader: '',
    }
  },
  computed: {

  },
  mounted() {
  },
  methods: {
    sendComment(){

    },
    deletePost() {
      socket.socket.emit('deletePost', { room: this.auth.nodeID, uname: this.user.username, postID: this.post.postID })
    }
  }
}
</script>

<style scoped>
  .profileImg{
    width: 50px;
    height: 50px;
    border-radius: 100%;
  }
  .wallPost{
    margin: 16px 0;
    border-radius: 8px;
    background-color: rgba(255,255,255,1);
  }
  .wallPostHead{
    clear: both;
    padding: 8px;
  }
  .wallPostHead .profileImg{
    display: inline;
    float: left;
  }
  .wallPostHead .profileImgRightContent{
    display: inline;
    text-align: left;
  }
  .wallPostHead .profileImgRightContent .userName{
    font-weight: bold;
    padding-left: 60px;
    padding-top: 2px;
  }
  .wallPostHead .profileImgRightContent .city{
    padding-left: 60px;
    font-size: 0.9em;
    color: rgba(0,0,0,0.6);
    margin-top: -4px;
  }
  .wallPostHead .time{
    font-size: 0.9em;
    color: rgba(0,0,0,0.6);
    text-align: right;
    padding-top: 4px;
  }
  .wallPostHead .time i{
    margin-top: 8px;
    vertical-align: top;
  }
  .wallPost .content{
    background-color: #009ad6;
    text-align: center;
    color: rgba(255,255,255,1);
    font-size: 1.4em;
    padding: 16px;
    padding-top: 50%;
    min-height: 300px;
    position: relative;
  }
  .wallPost .content p{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 32px);
    overflow: hidden;
  }
  .wallPost .comments{
    padding: 16px;
  }
  .wallPost .comments .comment{
    margin-bottom: 8px;
    font-size: 0.9em;
  }
</style>