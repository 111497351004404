<template>
  <div id="BackendTopMenu" class="ui large menu">
    <div class="grid-16">
      <div class="col-sp-16 col-tb-6 row-sp-16">
        <div class="left menu">
          <button class="btn btn-link btn-44 item pad-16" @click="config.sidebar = !config.sidebar">
            <span class="icon material-icons">menu</span>
          </button>
          <router-link to="/search" class="item">
            <i class="search icon" />
          </router-link>
        </div>
      </div>
      <div class="col-sp-16 col-tb-4 row-sp-16">
        <div id="Desktop-Top-Logo" class="t-center">
          <router-link active-class="active" to="/wall">
            <img v-if="!config.darkMode" class="logo_main" style="max-height: 45px; padding-top: 4px;" src="/assets/images/wurstsalat-beta-pride.png">
            <img v-else class="logo_main" style="max-height: 45px; padding-top: 4px;" src="/assets/images/dark-wurstsalat-beta-pride.png">
          </router-link>
        </div>
      </div>
      <div class="col-sp-16 col-tb-6 row-sp-16">
        <div class="right menu">
          <div class="item" @click="chat.visible = !chat.visible">
            <i class="comments icon" />
            <div v-if="chat.counter > 0" class="floating ui blue tiny circular label">
              {{ chat.counter }}
            </div>
          </div>
          <notification-nav size="small" class="item" style="margin-left: -16px; margin-right: -16px;">
            <i class="bell icon" />
          </notification-nav>
          <div class="ui item dropdown">
            <div class="text">
              <div v-if="socket.online">
                <img class="ui avatar image ar-1-1" :src="config.projectURL + 'assets/images/user/' + user.profile_img">
              </div>
              <div v-else class="ui segment">
                <div class="ui active loader" />
              </div>
            </div>
            <i class="dropdown icon" />
            <div class="menu">
              <router-link active-class="active" class="item" :to="'/p/' + user.username">
                <i class="user icon" />Mein Profil
              </router-link>
              <router-link active-class="active" class="item" to="/settings">
                <i class="settings icon" />Einstellungen
              </router-link>
              <a class="item logout" @click="auth.logout">
                <i class="logout icon" />Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import friendsNav from '@/components/basic/navbar/friendsNav'
import notificationNav from '@/components/basic/navbar/notificationNav'
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {chatStore} from "@/store/chatStore";
import {socketStore} from "@/store/socketStore";

export default {
  name: 'DesktopTop',
  components: {
    notificationNav
  },
  setup() {
    const auth = authStore()
    const user = userStore()
    const config = configStore()
    const chat = chatStore()
    const socket = socketStore()

    return {auth, user, config, chat, socket}
  },
  data () {
    return {
    }
  },
  computed: {
    logo () {
      return this.darkMode ? 'assets/images/dark-wurstsalat-beta-pride.png' : 'assets/images/wurstsalat-beta-pride.png'
    }
  },
  mounted(){
  },
  methods: {
    /*
    updateReads () {
      window.wurstsalat.updateReads()
    },
    openProfile (username) {
      openProfile(username)
    },
    openPost (post, comment) {
      openPost(post, comment)
    },
    acceptFriend (id) {
      $.post(window.projectURL + 'profile/friendRequestAccept', { id: id, postkey: sessionStorage.getItem('postkey'), SessionID: sessionStorage.getItem('SessionID') }, function (data, status, xhr) {
        if (xhr.status === 250) {
          for (var i = 0; i < window.wurstsalat.friends.length; i++) {
            if (window.wurstsalat.friends[i].id === id) {
              window.wurstsalat.friends.splice(i, 1)
            }
          }
        }
      })
    },
    declineFriend (id) {
      $.post(window.projectURL + 'profile/friendRequestDelete', { id: id, postkey: sessionStorage.getItem('postkey'), SessionID: sessionStorage.getItem('SessionID') }, function (data, status, xhr) {
        if (xhr.status === 250) {
          for (var i = 0; i < window.wurstsalat.friends.length; i++) {
            if (window.wurstsalat.friends[i].id === id) {
              window.wurstsalat.friends.splice(i, 1)
            }
          }
        }
      })
    }
    */
  }
}
</script>

<style>
#BackendTopMenu{
  border-radius: 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1032;
  background-color: rgba(255,255,255,1);
  margin-top: 0px;
  height: 58px;
  border: none;
  box-shadow: none;
}
#BackendTopMenu .item:before{
  display: none;
  width: 0px!important;
}
#BackendTopMenu .grid-16{
  width: 100%;
  height: 58px;
}
#BackendTopMenu .right.menu{
  float: right;
  height: 58px;
}
#BackendTopMenu .right.menu img:not(.avatar){
  max-height: 20px;
  width: auto;
  cursor: pointer;
}
#BackendTopMenu .menu i{
  color: rgba(0,0,0,0.4);
}
#BackendTopMenu .right.menu .ui.blue.label{
  background-color: #320b49!important;
  border-color: #320b49!important;
}
#BackendTopMenu .menu-btn.btn.btn-link.btn-44.item.pad-16{
  height: 56px;
  width: 56px;
  padding-right: 42px!important;
}
#BackendTopMenu #Desktop-Top-Logo img{
  max-height: 52px;
  margin-top: 3px;
  cursor: pointer;
}
#BackendTopMenu .floating.ui.label{
  top: 0.4em;
  left: 80%;
}
/* Darkmode */
.dark #BackendTopMenu{background-color: #1B1C1D;}
.dark #BackendTopMenu .menu i, .dark #BackendTopMenu .menu span{color: rgba(255,255,255,0.4);}
.dark #Mobile-Top-Menu button .icon{color: rgba(255,255,255,0.6);}
</style>
