<template>
  <!-- Modals -->
  <div
    id="ChatVideoModal"
    class="ui modal large"
  >
    <i class="close icon" />
    <video
      id="ChatVideoTag"
      controls
    />
  </div>
  <div
    id="ChatImageModal"
    class="ui modal large"
  >
    <i class="close icon" />
    <img class="chat-image">
  </div>
  <div
    v-if="revokeModal"
    class="vueModal"
  >
    <div class="vueModalInner content">
      <i class="close icon" />
      <div class="header">
        Biste du dir sicher?
      </div>
      <div class="content">
        Diese Nachricht wird für dich und deinen Chat-Partner gelöscht. Dies kann nicht rückgängig gemacht werden.
      </div>
      <div class="actions">
        <div
          class="ui tiny button"
          @click="revokeModal = false"
        >
          Abbrechen
        </div>
        <div
          class="ui tiny button approve btn-color-1"
          @click="revoke"
        >
          Löschen
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="delModal"
    class="vueModal"
  >
    <div class="vueModalInner content">
      <i class="close icon" />
      <div class="header">
        Biste du dir sicher?
      </div>
      <div class="content">
        Dies lässt sich nicht umkehren.
      </div>
      <div class="actions">
        <div
          class="ui tiny button"
          @click="delModal = false"
        >
          Abbrechen
        </div>
        <div
          class="ui tiny button btn-color-1"
          @click="clearChat(0);"
        >
          Für mich
        </div>
        <div
          class="ui tiny button btn-color-1"
          @click="clearChat(1);"
        >
          Für beide
        </div>
      </div>
    </div>
  </div>
  <div
    id="ChatMenuModal"
    class="ui mini modal"
  >
    <i class="close icon" />
    <div class="header">
      Menü
    </div>
    <div class="content">
      <button
        id="modal_fav"
        class="ui fluid icon labeled basic button"
        onclick="window.chat.favorite();"
      >
        <i class="icon outline star" />
        Als Favorit hinzufügen
      </button>
      <div class="pad-4" />
      <button
        class="ui fluid icon labeled basic button"
        onclick="$('.clearChat.modal').modal('show');"
      >
        <i class="icon trash" />Nachrichtenverlauf leeren
      </button>
      <div class="pad-4" />
      <button
        id="modal_block"
        class="ui fluid icon labeled basic button"
        onclick="window.chat.block();"
      >
        <i class="icon ban" />Person blockieren
      </button>
      <div class="pad-4" />
      <button class="ui fluid icon labeled basic button chatReport">
        <i class="icon flag" />Person melden
      </button>
    </div>
    <div class="actions">
      <div
        style="margin-left: 0;"
        class="ui fluid tiny button"
        onclick="$('#ChatMenuModal').modal('hide');"
      >
        Abbrechen
      </div>
    </div>
  </div>
  <div
    id="recordPlayer"
    class="ui large modal"
  >
    <div id="recordCircle">
      <div
        id="playButton"
        class="recPlay"
      />
      <svg
        id="recordButton"
        width="190"
        height="190"
      >
        <path id="recordTimer1" />
        <path id="recordTimer2" />
      </svg>
    </div>
  </div>
  <div
    id="NewChatModal"
    class="ui mini modal"
  >
    <i class="close icon" />
    <div class="header">
      Neuen Chat beginnen
    </div>
    <div class="pad-16">
      <div class="ui search">
        <div class="ui icon fluid input">
          <input
            id="search-contactboxes"
            class="prompt"
            type="text"
            placeholder="Suche.."
          >
          <i class="search icon" />
        </div>
      </div>
    </div>
    <div
      id="rainbowloader"
      class="rainbowloader"
    >
      <div class="loader-inner">
        <div class="loader-line-wrap">
          <div class="loader-line" />
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line" />
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line" />
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line" />
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line" />
        </div>
      </div>
    </div>
    <div
      id="AllContacts"
      class="content"
    />
    <div class="actions">
      <div
        class="ui tiny button"
        onclick="$('#NewChatModal').modal('hide');"
      >
        Abbrechen
      </div>
    </div>
  </div>
  <!-- Modals End -->
  <div
    id="Messenger"
    class="notchFix messenger-foreground"
  >
    <nav id="MessengerMobileMenu">
      <div class="navItem">
        <a
          class="goBack"
          @click="chat.visible = false"
        >
          <i class="angle left icon" />
        </a>
      </div>
      <div class="navItem">
        <img
          class="ui avatar image"
          :src="config.projectURL + 'assets/images/user/' + user.profile_img"
        >
      </div>
      <div class="navItem">
        <h3 class="ui header">
          Wurstsalat
          <div class="sub header">
            Messenger
          </div>
        </h3>
      </div>
      <div class="navItem f-right display-Mobile">
        <a
          class="goSettings"
          href="notifications"
        >
          <i class="bell outline icon" />
        </a>
      </div>
    </nav>
    <section id="MessengerLeftSide">
      <div
        id="MessengerSearch"
        class="pad-8"
      >
        <div class="ui search">
          <div class="ui icon input">
            <input
              id="search-chatboxes"
              class="prompt"
              type="text"
              placeholder="Suche.."
            >
            <i class="search icon" />
          </div>
        </div>
        <button
          class="addChat"
          onclick="window.chat.newContact();"
        >
          <i class="plus square outline icon" />
        </button>
      </div>
      <div class="chatList">
        <div id="ChatsHeader">
          <div class="ui header">
            Chats
          </div>
        </div>
        <div id="MessengerContacts">
          <div id="MessengerContactsFav" />
          <div id="MessengerContactsList">
            <div
              v-if="loading"
              class="rainbowloader"
            >
              <div class="loader-inner">
                <div class="loader-line-wrap">
                  <div class="loader-line" />
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line" />
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line" />
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line" />
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line" />
                </div>
              </div>
            </div>
            <partner
              v-for="partner in partnerList"
              :key="partner.id"
              :partner="partner"
              @open="(id) => currentPartner = id"
            />
          </div>
          <div
            id="MessageRequests"
            onclick="window.chat.toggleChat();"
          >
            0 Neue Nachrichtenanfragen
          </div>
        </div>
      </div>
      <div class="newList">
        <div id="ChatsHeaderNew">
          <div class="ui header">
            Nachrichtenanfragen
          </div>
        </div>
        <div id="MessengerContactsNew">
          <div id="MessengerContactsListNew" />
          <div
            id="MessageChats"
            onclick="window.chat.toggleChat();"
          >
            0 ungelesene Chats
          </div>
        </div>
      </div>
    </section>
    <section id="MessengerRightSide">
      <div id="MessengerChatPartner">
        <span class="chat-partner-item display-Mobile-inline">
          <a
            class="goBack"
            onclick="window.chat.mobileBack()"
          >
            <i class="angle left icon" />
          </a>
        </span>
        <a
          class="chat-partner-item chat-partner-profile-img chat-partner-link"
          @click="openProfile"
        >
          <img
            id="MessengerProfileImage"
            class="ui avatar image"
          >
          <span
            id="Online-User"
            class="onlinestatus ui green empty circular label"
          />
        </a>
        <a
          class="chat-partner-item chat-partner-link"
          @click="openProfile"
        >
          <h3
            id="MessengerName"
            class="ui header"
          >
            <div
              id="MessengerUsername"
              class="sub header"
            />
          </h3>
        </a>
        <div class="chat-partner-item">
          <i
            id="MessengerVerified"
            class="check circle blue icon"
          />
        </div>
        <div class="chat-partner-item f-right">
          <!--
          <a id="videoRequestLink">
            <i class="icon video"></i>
          </a>
          -->
          <a onclick="$('#ChatMenuModal').modal('show');">
            <i class="icon ellipsis vertical" />
          </a>
        </div>
      </div>
      <div id="MessengerChatContent">
        <div
          id="ChatContentloader"
          class="rainbowloader"
        >
          <div class="loader-inner">
            <div class="loader-line-wrap">
              <div class="loader-line" />
            </div>
            <div class="loader-line-wrap">
              <div class="loader-line" />
            </div>
            <div class="loader-line-wrap">
              <div class="loader-line" />
            </div>
            <div class="loader-line-wrap">
              <div class="loader-line" />
            </div>
            <div class="loader-line-wrap">
              <div class="loader-line" />
            </div>
          </div>
        </div>
      </div>
      <div id="MessengerChatNew">
        <div class="pad-16">
          <p>Du hast eine Nachrichtenanfrage. Wie möchtest du fortfahren?</p>
        </div>
        <button
          id="block-new-chat"
          type="button"
          class="ui button icon labeled"
        >
          <i class="ban icon" />Blockieren
        </button>
        <button
          type="button"
          class="ui button icon labeled chatReport"
        >
          <i class="flag icon" />Melden
        </button>
        <button
          id="decline-new-chat"
          type="button"
          class="ui button icon labeled"
        >
          <i class="cancel icon" />Ablehnen
        </button>
        <button
          id="accept-new-chat"
          type="button"
          class="ui button icon labeled btn-color-1"
        >
          <i class="check icon" />Annehmen
        </button>
      </div>
      <div id="MessengerChatActions">
        <div
          id="MediaSlider"
          class="chat-mediaslider w-clearfix"
          style="display: block;"
        >
          <div
            id="MediaSliderWrapper"
            class="chat-mediaslider-wrapper"
          >
            <div
              id="up-1624216176161"
              class="chat-mediaslider-media w-clearfix"
            >
              <a
                class="chat-mediaslider-media-delete w-button"
                onclick="window.chat.removeUploadedFile('1624216176161')"
              >X</a>
            </div>
          </div>
          <label
            id="chatAddFile"
            class="chat-mediaslider-media-add w-button"
            for="chatUpload"
          >
            +
          </label>
        </div>
        <div id="GifMenu">
          <div>
            <div class="ui action input fluid">
              <input
                id="searchTenor"
                type="text"
                placeholder="Suche nach GIFs..."
              >
              <i
                id="doSearchButton"
                class="ui button"
                onclick="javascript:grab_data_search(document.getElementById('searchText').value);"
              >Suchen</i>
            </div>
          </div>

          <div id="tenor-container" />
        </div>
        <emojimenu />
        <input
          id="chatUpload"
          type="file"
          name="chatUpload[]"
          style="display: none;"
          multiple
        >
        <form id="chatForm">
          <div
            id="MessengerChatActionsTop"
            class="ui form"
          >
            <div
              id="messageContentSlider"
              class="chat-actions field"
            >
              <textarea
                id="messageContent"
                rows="1"
                placeholder="Nachricht..."
              />
            </div>
            <div
              id="messageAudioSlider"
              class="chat-actions"
            >
              <audio
                id="rec_audio"
                controls
              />
            </div>
            <div
              class="chat-actions"
              style="float: right;"
            >
              <button
                id="recordAudio"
                type="button"
                class="circular ui icon button btn-color-1"
              >
                <i class="microphone icon" />
              </button>
              <div id="timeAudioBox">
                <i
                  id="recMic"
                  class="microphone icon"
                />
                <button
                  id="stopAudio"
                  type="button"
                  class="circular ui icon button"
                >
                  <i class="stop icon" />
                </button>
                <button
                  id="sendChatRec"
                  type="submit"
                  class="circular ui icon button"
                  style="display: none;"
                >
                  <i class="send icon" />
                </button>
                <button
                  id="deleteAudio"
                  type="button"
                  class="circular ui icon button"
                  style="display: none;"
                >
                  <i class="trash icon" />
                </button>
                <button
                  id="playRecord"
                  type="button"
                  class="circular ui icon button"
                  style="display: none;"
                >
                  <i class="play icon" />
                </button>
                <button
                  id="pauseRecord"
                  type="button"
                  class="circular ui icon button"
                  style="display: none;"
                >
                  <i class="pause icon" />
                </button>
                <p id="timeAudio" />
                <div
                  id="recordingWave"
                  class="wave-container"
                />
              </div>
              <button
                id="sendChat"
                type="submit"
                class="circular ui icon button btn-color-1"
                style="display: none;"
              >
                <i class="send icon" />
              </button>
            </div>
          </div>
        </form>
        <input
          type="hidden"
          name="MAX_FILE_SIZE"
          value="200000000"
        >
        <div id="MessengerChatActionsBottom">
          <div
            id="smileys"
            class="chat-actions"
            title="Emoticons"
          >
            <button class="circular ui icon button">
              <i class="icon smile outline" />
            </button>
          </div>
          <div
            class="chat-actions"
            title="Bild senden"
          >
            <button
              type="button"
              class="circular ui icon button"
              onclick="window.chat.openFileUpload('image');"
            >
              <i class="icon image outline" />
            </button>
          </div>
          <div
            class="chat-actions"
            title="Video senden"
          >
            <button
              type="button"
              class="circular ui icon button"
              onclick="window.chat.openFileUpload('video');"
            >
              <i class="icon film" />
            </button>
          </div>
          <div
            class="chat-actions"
            title="Audio senden"
          >
            <button
              type="button"
              class="circular ui icon button"
              onclick="window.chat.openFileUpload('audio');"
            >
              <i class="itunes note icon" />
            </button>
          </div>
          <div
            class="chat-actions"
            title="Gifs senden"
          >
            <button
              id="Gifs"
              type="button"
              class="circular ui icon button"
            >
              GIF
            </button>
          </div>
          <div
            class="chat-actions"
            title="Selbstzerstörende Nachricht senden"
            onclick="window.chat.openFileUpload('destroy');"
          >
            <button
              type="button"
              class="circular ui icon button"
            >
              <i class="stopwatch icon" />
            </button>
          </div>
        </div>
      </div>
      <div id="chat-blocked" />
    </section>
    <section
      id="MessengerStart"
      class="pad-40"
    >
      <div class="content-cover">
        <div class="ui segment no-border chat-info">
          <h2 class="ui header">
            <img src="assets/images/emoticons/hi.png">
            <div class="content">
              Hey,
              <div class="sub header">
                willkommen zurück im Wurstsalat Messenger.
              </div>
            </div>
          </h2>
          <p>
            Wir haben den Chat grundlegend überarbeitet und einige neue Funktionen hinzugefügt.
            Folgendes hat sich geändert:<br><br>

            <img
              src="assets/images/emoticons/1f5d1.png"
              style="max-width: 24px;"
            > Neue Funktion: Nachrichten löschen<br>
            <img
              src="assets/images/emoticons/1f4a5.png"
              style="max-width: 24px;"
            >  Neue Funktion: Selbstzerstörende Nachrichten<br>
            <img
              src="assets/images/emoticons/1f60a.png"
              style="max-width: 24px;"
            >  Überarbeitet: Gifs und Emojis verschicken<br>
            <img
              src="assets/images/emoticons/1f399.png"
              style="max-width: 24px;"
            >  Überarbeitet: Sprachnachrichten verschicken<br>
            <img
              src="assets/images/emoticons/1f4e9.png"
              style="max-width: 24px;"
            >  Überarbeitet: Bilder und Videos versenden<br>
            <br>
            Mehr zu den Änderungen und kommenden Neuerungen findest du auf unserer Instagram Seite
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Emojimenu from '@/components/plugins/emojimenu'
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {chatStore} from "@/store/chatStore";
import Partner from "@/components/chat/partner";

export default {
  name: 'Messages',
  components: {Partner, Emojimenu },
  setup() {
    const user = userStore()
    const config = configStore()
    const chat = chatStore()

    return { user, config, chat }
  },
  data () {
    return {
      loading: true,
      currentPartner: -1,
    }
  },
  computed: {
    partnerList() {
      return [] /*
      let list = this.chat.chats
      list.sort((a,b) => {
         return a.messages.at(-1) - b.messages.at(-1)
        })
      return list */
    }
  },
  methods: {
  }
}
</script>

<style>
@import '../assets/css/emojione.sprites.css';
#Messenger{
  position: fixed;
  background-color: rgba(255,255,255,1);
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  height: 100%;
}
#MessengerLeftSide{
  width: 30%;
  height: 100%;
  display: inline-block;
  border-right: 1px solid rgba(0,0,0,0.05);
}
#MessengerLeftSide #MessengerSearch .ui.search{
  display: inline-block;
  width: 85%;
}
#MessengerLeftSide #MessengerSearch .ui.search .ui.icon.input{width: 100%;}
#MessengerLeftSide #MessengerSearch .addChat{
  display: inline-block;
  font-size: 1.8em;
  background: transparent;
  border: none;
  float: right;
  color: rgba(0,0,0,0.6);
  outline: none;
}
#MessengerContacts, #MessengerContactsNew{padding-top: 8px;}
#MessengerContacts{
  overflow-y: scroll;
  padding-bottom: 40px;
  max-height: calc(100vh - 200px);
  min-height: 350px;
}
#AllContacts{
  overflow-y: scroll;
  max-height: 300px;
  min-height: 300px;
  padding: 0;
}
#ChatsHeader .ui.header, #ChatsHeaderNew .ui.header{
  padding: 0 16px;
  margin-top: 16px;
}
.contact-element{
  padding: 16px 16px 8px 16px;
  border-top: 1px solid rgba(0,0,0,0.05);
  vertical-align: middle;
  cursor: pointer;
  position: relative;
}
.contact-element .ui.header i.check.icon{
  display: inline-block;
  font-size: 1em;
  margin-top: -5px;
}
.contact-element .contact-profile-img{
  display: inline-block;
  position: relative;
}
.contact-element .contact-profile-img .ui.avatar.image{
  height: 3em;
  width: 3em;
  margin-top: -18px;
}
.contact-element .contact-profile-img .onlinestatus{
  position: absolute;
  top: 7px!important;
  left: 32px;
}
.contact-element .contact-content{display: inline-block;}
.contact-element .contact-content .typing{
  font-style: italic;
  font-weight: normal;
  opacity: 0.8;
}
.contact-element .contact-extra{
  position: absolute;
  right: 16px;
}
.contact-element .contact-extra .time, .contact-element .contact-extra .favorite{
  margin: 0;
  padding: 0;
  text-align: right;
}
.contact-element .contact-extra .favorite i.icon.circle{color: rgba(57,0,85,0.5);}
.contact-element .contact-extra .favorite i.icon.heart{color: rgba(255,0,0,0.5);}
.contact-element .contact-extra .favorite i.icon.star{color: rgba(245,215,110,1);}
#MessageRequests, #MessageChats{
  position: absolute;
  bottom: 0;
  width: 30%;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  color: rgba(255,255,255,1);
  padding: 16px;
  background-image: linear-gradient(to right, #390055 , #4bb7c3);
}
#MessageRequests{display: none;}
#MessengerRightSide{
  width: 70%;
  height: 100%;
  display: inline-block;
  position: absolute;
  top: 0;
}
#MessengerMobileMenu{
  width: 100%;
  padding: 8px 16px;
  background-color: rgba(0,0,0,0.02);
}
#MessengerMobileMenu .navItem{
  display: inline-block;
  color: rgba(0,0,0,0.6);
  vertical-align: middle;
}
#MessengerMobileMenu .navItem .goBack{
  font-size: 2em;
  cursor: pointer;
}
#MessengerMobileMenu .navItem .ui.avatar.image{
  width: 2em;
  height: 2em;
}
#MessengerMobileMenu .navItem .goSettings{
  font-size: 1.5em;
  cursor: pointer;
}
#MessengerMobileMenu .navItem.f-right{
  float: right;
  padding-top: 6px;
}
#MessengerChatPartner{
  width: 100%;
  padding: 8px 16px;
  background-color: rgba(0,0,0,0.02);
  position: relative;
}
#MessengerChatPartner .chat-partner-item{
  display: inline-block;
  color: rgba(0,0,0,0.6);
  vertical-align: middle;
}
#MessengerChatPartner .chat-partner-item .goBack{
  font-size: 2em;
  cursor: pointer;
}
#MessengerChatPartner .chat-partner-item.chat-partner-profile-img{position: relative;}
#MessengerChatPartner .chat-partner-item.chat-partner-profile-img .ui.avatar.image{
  width: 2.3em;
  height: 2.3em;
}
#MessengerChatPartner .onlinestatus {
  margin-left: -12px!important;
  margin-right: 4px!important;
  margin-bottom: -16px!important;
  z-index: 2;
  position: relative;
}
#MessengerChatPartner .chat-partner-item i.check.icon{
  vertical-align: top;
  margin-top: -10px;
}
#MessengerChatPartner .chat-partner-item.f-right{
  float: right;
  padding-top: 6px;
}
#MessengerChatPartner .chat-partner-item.f-right a{
  font-size: 1.2em;
  padding: 10px;
  cursor: pointer;
}
#MessengerChatContent{
  position: absolute;
  width: 100%;
  height: calc(100% - 185px);
  overflow-y: scroll;
  padding: 16px;
  overflow-x: hidden;
}
#MessengerChatContent .message{
  padding-top: 18px;
  clear: both;
}
#MessengerChatContent .message .chat_message_img{
  max-width: 200px;
  width: 100%;
  cursor: pointer;
}
#MessengerChatContent .message .message-profile-img{display: inline-block;}
#MessengerChatContent .message .message-content{
  display: inline-block;
  background-color: rgba(0,0,0,0.05);
  border-radius: 8px;
  color: rgba(0,0,0,0.9);
  padding: 8px;
  position: relative;
  max-width: calc(100% - 80px);
  overflow-wrap: break-word;
}
.message-content.destroy.viewed{
  opacity: 0.8;
  font-style: italic;
}
#MessengerChatContent .message .message-content audio{max-width: 100%;}
#MessengerChatContent .message .message-content.typing{opacity: 0.8;}
#MessengerChatContent .message .message-content.more-media{
  margin-left: 44px;
  display: table;
  margin-top: 4px;
}
#MessengerChatContent .message .message-right .message-content.more-media{
  clear: right;
  margin-right: 44px;
  margin-top: 4px;
}
#MessengerChatContent .message .message-right .message-content{
  float: right;
  background-color: rgba(57,0,85,0.4);
  color: rgba(255,255,255,0.9);
}
#MessengerChatContent .message .message-right .message-profile-img{
  float: right;
  margin-left: 8px;
}
#MessengerChatContent .message .chat-message-text-deleted{
  font-style: italic;
  opacity: 0.8;
}
#MessengerChatContent .message .message-actions{display: inline-block;}
#MessengerChatContent .message .chat-time{display: inline-block;}
#MessengerChatContent .message .message-info{
  position: absolute;
  text-align: right;
  bottom: -22px;
  right: 0;
  padding: 2px;
  font-size: 0.8em;
  color: rgba(0,0,0,0.5);
  min-width: 140px;
}
#MessengerChatContent .message .hat-message-text-deleted{
  color: rgba(0,0,0,0.2);
  font-style: italic;
}
#MessengerChatContent .message .message-right .hat-message-text-deleted{
  color: rgba(255,255,255,0.2);
  font-style: italic;
}
#MessengerChatContent .ui.horizontal.divider.header{
  color: rgba(0,0,0,0.2);
  width: 100%;
  padding: 24px 0px;
}
#MessengerChatActions{
  position: absolute;
  width: 100%;
  min-height: 120px;
  bottom: 0;
  border-top: 1px solid rgba(0,0,0,0.05);
  background: rgba(255,255,255,0.95);
}
#MessengerChatActionsTop{
  padding: 16px;
  vertical-align: middle;
}
#MessengerChatActions .chat-actions{
  display: inline-block;
  margin-left: -4px;
}
#MessengerChatActions .chat-actions button{border-radius: 0;}
#MessengerChatActions .chat-actions.field{
  width: calc(100% - 40px);
  margin: 0;
}
#MessengerChatActions .chat-actions.field textarea{
  resize: none;
  margin-top: -10px;
  padding: 10px;
}
#MessengerChatActionsBottom{padding: 0 16px;}
#MessengerChatActionsBottom .chat-actions button{
  font-size: 18px;
  padding: 0;
  background: transparent;
  margin: 0 16px;
}
#MessengerChatActionsBottom .chat-actions button#Gifs{
  margin: 0px 12px;
  font-size: 16px;
}
#MessengerStart{
  width: 70%;
  height: 100%;
  display: inline-block;
  position: absolute;
  top: 0;
  z-index: 2;
  background-color: #ffffff;
  background-image: url('../assets/images/messenger-background.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 1240px) {
  #MessengerStart{
   display: none !important;
  }
}
#MessengerStart .content-cover{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.4);
  padding: 15% 40px 40px 40px;
}
#MessengerStart .content-cover .ui.segment.chat-info{
  padding: 40px;
  color: rgba(0,0,0,0.6);
  max-width: 500px;
  margin: 0 auto;
}
#NewChatModal .rainbowloader{min-height: 300px;}
#loadMoreMessages{
  margin: 0 auto;
  display: block;
  padding: 4px 8px;
  border-radius: 4px;
  background: rgba(0,0,0,0.05);
  border: none;
  color: rgba(0,0,0,0.6);
}
#chat-blocked{
  padding: 16px;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: rgba(57,0,85,0.5);
  color: rgba(255,255,255,1);
}
#GifMenu{
  display: none;
  height: 190px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 32px;
  width: 100%;
  box-sizing: border-box;
}
#EmoticonsMenu{display: none;}
.smiley-panel-body {
  height: 190px;
  position: absolute;
  opacity: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 8px 0 10px;
  width: 100%;
  box-sizing: border-box;
}
.smiley-panel .e1 {
  margin: 6px;
  cursor: pointer;
  border-radius: 3px;
}
.emoji, .e1 {
  display: inline-block;
  vertical-align: top;
  zoom: 1;
  border: 0;
}
.e1 {
  width: 32px;
  height: 32px;
  background-size: 1502px 1467px;
}
.chat-mediaslider {
  padding-top: 6px;
  padding-bottom: 6px;
}
.chat-mediaslider-media-add {
  background-position: 50% 15%;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  width: 80px;
  height: 140px;
  margin-right: 6px;
  margin-left: 6px;
  padding: 0px;
  float: left;
  border: 2px dashed #e0e0e0;
  border-radius: 6px;
  background-color: #fff;
  font-family: Montserrat, sans-serif;
  color: #4bb7c3;
  font-size: 38px;
  line-height: 140px;
  font-weight: 400;
  text-align: center;
}
.chat-mediaslider-wrapper {
  margin-left: 9px;
  float: left;
  display: flex;
}
.chat-mediaslider-media {
  margin: 0 6px;
  width: 80px;
  height: 140px;
  border-radius: 6px;
  background-position: 50% 50%;
  background-size: cover;
}
.chat-mediaslider-media-delete {
  width: 20px;
  height: 20px;
  margin-top: 6px;
  margin-right: 6px;
  padding: 0px;
  float: right;
  border-radius: 50%;
  background-color: #4bb7c3;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
}
#chatForm{clear: both;}
#Messenger .display-Dektop{display: block;}
#Messenger .display-Mobile{display: none;}
#Messenger .display-Dektop-inline{display: inline-block;}
#Messenger .display-Mobile-inline{display: none;}
/* Media Querys */
@media (max-width: 1240px){
  #Messenger .display-Dektop{display: none;}
  #Messenger .display-Mobile{display: block;}
  #Messenger .display-Dektop-inline{display: none;}
  #Messenger .display-Mobile-inline{display: inline-block;}
  #MessengerLeftSide{
    display: block;
    width: 100%;
    max-width: 100%;
  }
  #MessengerRightSide{
    display: none;
    width: 100%;
    max-width: 100%;
    position: relative;
  }
  #MessengerStart{
    display: none;
  }
  #Messenger{
    top: 0;
    height: 100%;
  }
  #MessageRequests{
    max-width: 100%;
    width: 100%;
  }
  #MessengerChatActionsBottom .chat-actions {
    width: 16.5%;
    text-align: center;
  }
  #MessageRequests, #MessageChats{
    width: 100%;
  }
  #MessengerChatNew button{
    margin: 4px;
  }
  #MessengerChatNew button{
    margin: 4px;
    display: block;
    width: 100%;
    padding: 8px 16px;
  }
}
.newList{display: none;}
.chat-like-heart{
  background: url('../assets/icons/heart.png');
  width: 15px;
  height: 12px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.8;
}
.chat-like-noheart{
  background: url('../assets/icons/heart_outline.png');
  width: 15px;
  height: 12px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.6;
}
.chat-delete{
  background: url('../assets/icons/bin_closed.png');
  width: 15px;
  height: 12px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.8;
}
.tenor-gif {
  display: inline-block;
  width: calc(20% - 8px);
  text-align: center;
  margin: 0 auto;
  padding: 4px;
}
.tenor-gif img{
  max-width: 100%;
  cursor: pointer;
}
@media (hover: hover){
  .chat-delete:hover{
    background: url('../assets/icons/bin_opened.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
  .chat-like-noheart:hover{
    background: url('../assets/icons/heart.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
  .chat-like-heart:hover{
    background: url('../assets/icons/heart_outline.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.chat-noHover{pointer-events: none!important;}
#MessengerChatNew{
  display: none;
  position: absolute;
  bottom: 0;
  padding: 32px;
  text-align: center;
  width: 100%;
}
.sub.header.last_dm{
  height: 16px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sub.header.last_dm i.icon{margin-right: 2px!important;}
.sub.header.last_dm .chat-message-text-deleted{
  font-style: italic;
  opacity: 0.8;
}
#MessengerContactsList{
  position: relative;
  overflow: hidden;
}
#contactListLoader, #ChatContentloader{
  position: absolute;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  margin-top: 20%;
}
#ChatImageModal, #ChatVideoModal{
  background: transparent;
  box-shadow: none;
  text-align: center;
}
#ChatImageModal .chat-image{
  max-width: calc(100% - 32px);
  max-height: calc(100vh - 32px);
}
#ChatVideoModal #ChatVideoTag{
  max-width: calc(100%);
  max-height: calc(100vh);
  width: 100%;
}
#messageAudioSlider{
  width: calc(100% - 100px);
  background-color: rgba(0,0,0,0.05);
}
#timeAudioBox{
  background: #E0E1E2;
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
  width: auto;
  padding: 8px;
  position: absolute;
  display: none;
  right: 0;
  top: 7px;
  border-radius: 20px;
  margin: 10px;
}
#timeAudio{
  display: inline-block;
  float: left;
  margin: 0 5px 0 0;
}
#deleteAudio {
  margin: 3px 45px 0 55px !important;
  padding: 0;
  float: right;
}
#playRecord, #sendChatRec, #pauseRecord, #stopAudio{
  padding: 0;
  float: right;
  margin: 3px 0 0 10px !important;
}
#recMic {float: left;}
#recordAudio, #sendChat{margin-top: -8px;}
.message-right {max-width: 90%;margin-left:10%;}
.message-left {max-width: 90%;}
.wave-container{
  width: auto;
  height: 20px;
  float: none;
  overflow: hidden;
}
.wave-segment {
  width: 2px;
  margin-left: 1px;
  float: left;
  bottom: 0;
  height: 100%;
  position: relative;
}
.wave-segment.recording {float: right;}
.wave {
  position: absolute;
  background-color: #b099bb;
  bottom: 0;
  width: 100%;
  border-radius: 50% 50% 0 0;
  min-height: 1px;
  display: none;
  transition: background-color 300ms linear, opacity 300ms linear;
}
.message-right .wave {background-color: #f2f2f2;}
.speed-container.active {
  background-color: #390055 !important;
  color: #f2f2f2 !important;
}
.played .wave {background-color: #390055;}
.time-container {
  float: right;
  height: 20px;
  font-size: 75%;
  margin-top: 5px;
  margin-left: 5px;
  overflow: hidden;
}
.message-right .time-container{float: left;}
.speed-container{
  text-align: center;
  border-radius: 5px;
  float: left;
  width: 0;
  height: 20px;
  font-size: 75%;
  margin-top: 5px;
  margin-right: 5px;
  overflow: hidden;
  background-color: #b099bb;
}
.message-right .speed-container {
  float: left;
  background-color: #f2f2f2;
  color: #390055;
}
.message-right .time-container{float: right;}
.chat-content{max-width: 100%;}
.play-button{
  color: #390055;
  float: left;
}
.message-content.message-img{
  padding: 0 !important;
  overflow: hidden;
}
/* Darkmode */
.dark #Messenger{background-color: #1B1C1D;}
.dark #MessengerLeftSide{background: #1B1C1D;border-right: 1px solid rgba(0,0,0,0.05);}
.dark #MessengerLeftSide #MessengerSearch .ui.input > input{background-color: #141414;color: rgba(255,255,255,1);}
.dark #MessengerLeftSide #MessengerSearch .ui.input i{color: rgba(255,255,255,1);}
.dark #MessengerLeftSide #MessengerSearch .addChat{color: rgba(255,255,255,0.6);}
.dark .contact-element{border-top: 1px solid rgba(0,0,0,0.05);}
.dark .contact-element .contact-extra .favorite i.icon.circle{color: #9bc3cf;}
.dark .contact-element .contact-extra .favorite i.icon.heart{color: rgba(255,0,0,0.5);}
.dark .contact-element .contact-extra .favorite i.icon.star{color: rgba(245,215,110,1);}
.dark #MessageRequests, #MessageChats{color: rgba(255,255,255,1);background-image: linear-gradient(to right, #390055 , #4bb7c3);}
.dark #MessengerRightSide{background-color: #141414;}
.dark #MessengerMobileMenu{background-color: #141414;}
.dark #MessengerMobileMenu .navItem{color: rgba(0,0,0,0.6);}
.dark #MessengerMobileMenu .navItem .goBack{color: #ffffff;}
.dark #MessengerChatPartner{background-color: rgba(0,0,0,0.02);}
.dark #MessengerChatPartner .chat-partner-item{color: rgba(0,0,0,0.6);}
.dark #MessengerChatPartner .chat-partner-item.f-right a{color: rgba(255,255,255,1);}
.dark #MessengerChatContent{background: #1B1C1D;}
.dark #MessengerChatContent .message .message-content{background-color: rgba(0,0,0,0.3);color: rgba(255,255,255,0.9);}
.dark #MessengerChatContent .message .message-right .message-content{background-color: rgba(57,0,85,0.4);color: rgba(255,255,255,0.9);}
.dark #MessengerChatContent .message .message-info{color: rgba(255,255,255,0.5);}
.dark #MessengerChatContent .message .hat-message-text-deleted{color: rgba(0,0,0,0.2);}
.dark #MessengerChatContent .message .message-right .hat-message-text-deleted{color: rgba(255,255,255,0.2);}
.dark #MessengerChatContent .ui.horizontal.divider.header{color: rgba(255,255,255,0.2);}
.dark #MessengerChatActions{border-top: 1px solid rgba(0,0,0,0.05);background: #1B1C1D;}
.dark .ui.tabular.menu .active.item{background-color: rgba(0,0,0,0.8);color: rgba(255,255,255,1);}
.dark #MessengerChatActions .chat-actions{color: rgba(255,255,255,0.9);}
.dark #MessengerChatActions .chat-actions .ui.button{color: rgba(255,255,255,0.9);}
.dark #MessengerStart{background-color: #1B1C1D;}
.dark #MessengerStart .content-cover .ui.segment.chat-info{color: rgba(255,255,255,0.6);}
.dark #loadMoreMessages{background: rgba(0,0,0,0.3);color: rgba(255,255,255,0.6);}
.dark #chat-blocked{background-color: rgba(57,0,85,0.5);color: rgba(255,255,255,1);}
.dark .chat-mediaslider-media-add {border: 2px dashed #000000;background-color: #1B1C1D;color: #4bb7c3;}
.dark .chat-mediaslider-media-delete {background-color: #4bb7c3;color: #fff;}
.dark #messageAudioSlider{background-color: rgba(0,0,0,0.05);}
.dark #timeAudioBox{color: #E0E1E2;background: rgba(0, 0, 0, 0.6);}
.dark #MessengerChatPartner .chat-partner-item .goBack{color: rgba(255,255,255,1);}
</style>
