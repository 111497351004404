<template>
  <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-16 pad-4">
    <article class="ui fluid card">
      <router-link
        class="image"
        :to="'/blog/a/' + article.id"
      >
        <img :src="projectURL + '/assets/images/magazin/' + article.article_img">
      </router-link>
      <div class="content">
        <router-link
          class="ui mini violet label"
          :to="'/blog/c/' + article.category_name"
        >
          {{ article.category_name }}
        </router-link>
        <div class="pad-4" />
        <router-link
          class="header inverted"
          :to="'/blog/a/' + article.id"
        >
          {{ article.article_title }}
        </router-link>
        <div class="meta">
          <a>{{ article.article_subtitle }}</a>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'MagazinArt',
  props: ['article'],
  data () {
    return {
      projectURL: window.projectURL
    }
  }
}
</script>

<style scoped>

</style>
