import { defineStore } from 'pinia'
import {socketStore} from "@/store/socketStore";
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export const chatStore = defineStore('chat', {
    state: () => ({
        chats: {},
        visible: false,
        force: false,
        lastChatID: -1, //newest id of messages
    }),
    getters: {
    },
    actions: {
        /**
         * register Listener for Socket.io
         */
        registerListener() {
            const _this = this
            const socket = socketStore()
            const auth = authStore()
            const user = userStore()

            /**
             * add new message to array
             */
            socket.socket.on('newMessage', (data) => {
                if (!socket.smids.includes(data.smid)) {
                    socket.smids.push(data.smid)
                    let chatID
                    if(data.sender === user.user_id){
                        chatID = data.recipient.id
                        if(!Object.prototype.hasOwnProperty.call(_this.chats, data.recipient)){
                            _this.chats[chatID] = data.recipient
                        }
                    }else if(data.recipient === user.user_id){
                        chatID = data.sender.id
                        if(!Object.prototype.hasOwnProperty.call(_this.chats, data.sender)){
                            _this.chats[chatID] = data.sender
                        }
                    }else{
                        return
                    }
                    if(!Object.prototype.hasOwnProperty.call(_this.chats[chatID].messages, data.message.id)){
                        _this.chats[chatID].messages[data.message.id] = data.message
                    }
                }
            })

            /**
             * mark a message as read
             */
            socket.socket.on('newRead', (data) => {
                try {
                    if(Object.prototype.hasOwnProperty.call(_this.chats, data.pid)){
                        _this.chats[data.pid].messages.forEach((value, index) => {
                            value.read = 1
                        })
                    }
                } catch (e) {}
            })

            /**
             * mark Chatbox as read by self
             */
            socket.socket.on('readChatBox', (data) => {
                try {
                    if(Object.prototype.hasOwnProperty.call(_this.chats, data)){
                        _this.chats[data].messages.forEach((value, index) => {
                            if(value.recipient === user.user_id) value.read = 1
                        })
                    }
                    socket.socket.emit('readChatBox', { room: auth.nodeID, uname: user.username, pid: data })
                } catch (e) {}
            })

            /**
             * partner is typing
             */
            socket.socket.on('typing', (data) => {
                try {
                    if(Object.prototype.hasOwnProperty.call(_this.chats, data)){
                        _this.chats[data].typing = Date.now()
                    }
                } catch (e) {
                    console.error(e)
                }
            })

            /**
             * mark a message as liked
             */
            socket.socket.on('likeMessage', (data) => {
                try {
                    if(Object.prototype.hasOwnProperty.call(_this.chats, data.pid) && Object.prototype.hasOwnProperty.call(_this.chats[data.pid].messages, data.mid)){
                        _this.chats[data.pid].messages[data.mid].liked = true
                    }
                } catch (e) {
                    console.error(e)
                }
            })

            /**
             * mark message as revoked
             */
            socket.socket.on('revokeMessage', (data) => {
                try {
                    if(Object.prototype.hasOwnProperty.call(_this.chats, data.pid) && Object.prototype.hasOwnProperty.call(_this.chats[data.pid].messages, data.mid)){
                        _this.chats[data.pid].messages[data.mid].revoked = true
                        _this.chats[data.pid].messages[data.mid].content = ''
                    }
                } catch (e) {
                    console.error(e)
                }
            })

            /**
             * save favorite state on chatpartner
             */
            socket.socket.on('favorite', (data) => {
                try {
                    if(Object.prototype.hasOwnProperty.call(_this.chats, data.pid)){
                        _this.chats[data.pid].favorite = data.save === 0
                    }
                } catch (e) {
                    console.error(e)
                }
            })

            /**
             * mark online users
             */
            socket.socket.on('online', (data) => {
                try {
                    for (const key in data) {
                        if(Object.prototype.hasOwnProperty.call(_this.chats, data[key].id)){
                            _this.chats[data[key].id].online = data[key].online
                        }
                    }
                } catch (e) {
                    console.error(e)
                }
            })

            /**
             * delete messages from chatpartner for self
             */
            socket.socket.on('clearChat', (data) => {
                try {

                    if(Object.prototype.hasOwnProperty.call(_this.chats, data.pid)){
                        for(let key in _this.chats[data.pid].messages){
                            delete _this.chats[data.pid].messages[key]
                        }
                    }
                } catch (e) { }
            })

            /**
             * revoke all messages
             */
            socket.socket.on('revokeAll', (data) => {
                try {
                    if(Object.prototype.hasOwnProperty.call(_this.chats, data.pid)){
                        for(let key in _this.chats[data.pid].messages){
                            _this.chats[data.pid].messages[key].revoked = true
                            _this.chats[data.pid].messages[key].content = ''
                        }
                    }
                } catch (e) {
                    console.error(e)
                }
            })

            /**
             * handler for blocks
             */
            socket.socket.on('block', (data) => {
                try {
                    if (data.user1 == user.user_id) {
                        if(Object.prototype.hasOwnProperty.call(_this.chats, data.user2)){
                            _this.chats[data.user2].isBlocked = data.block
                        }
                    } else if (data.user2 == user.user_id) {
                        if(Object.prototype.hasOwnProperty.call(_this.chats, data.user1)){
                            _this.chats[data.user1].isBlocked = data.block
                        }
                    }
                } catch (e) {
                    console.error(e)
                }
            })
        },
    }
})