<template>
  <div id="Mobile-Bottom-Menu">
    <div class="footer-tabs border-top text-center">
      <ul class="nav nav-tabs justify-content-center">
        <li class="nav-item">
          <router-link active-class="active" class="nav-link " to="/wall" aria-controls="home" aria-selected="true" style="position: relative;">
            <i class="material-icons">home</i>
            <small class="sr-only">Home</small>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link active-class="active" class="nav-link " to="/n" aria-controls="search" aria-selected="false" style="position: relative;">
            <i class="material-icons">notifications_none</i>
          </router-link>
        </li>
        <li class="nav-item centerlarge">
          <div class="nav-link bg-default" aria-selected="false" style="position: relative;" @click="chat.visible = true">
            <i class="material-icons">chat_bubble_outline</i>
            <div v-if="chat.counter > 0" class="floating ui blue tiny circular label">
              {{ chat.counter }}
            </div>
          </div>
        </li>
        <li class="nav-item">
          <router-link active-class="active" class="nav-link " to="/search" data-acitve="search" aria-controls="favorite" aria-selected="false" style="position: relative;">
            <i class="material-icons">search</i>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link active-class="active" class="nav-link " :to="'/p/' + user.username" aria-controls="profile" aria-selected="false" style="position: relative;">
            <i class="material-icons">person</i>
            <small class="sr-only">person</small>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {chatStore} from "@/store/chatStore";

export default {
  name: 'MobileBottom',
  setup() {
    const auth = authStore()
    const user = userStore()
    const config = configStore()
    const chat = chatStore()

    return {auth, user, config, chat}
  }
}
</script>

<style scoped>

</style>
