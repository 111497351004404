<template>
  <div
    v-if="header.length > 0 || content.length > 0"
    class="container"
    :class="color"
  >
    <div
      v-if="header.length > 0 "
      class="header"
    >
      {{ header }} <i :class="icon" />
    </div>
    <div
      v-if="content.length > 0"
      class="content"
    >
      {{ content }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SmallBanner",
  props: ['icon', 'header', 'content', 'color']
}
</script>

<style scoped>
.red{
  border: 1px solid red;
  background-color: rgba(255, 0, 0, 0.4);
}
</style>