<template>
  <div v-if="size == 'small'">
    <div
      v-if="openAllNotis"
      id="notis"
      class="ui dropdown item"
      @click="allSeen()"
    >
      <i class="bell icon" />
      <div
        v-if="unread > 0"
        id="notifications-count"
        class="floating ui blue tiny circular label"
      >
        {{ unread }}
      </div>
      <div
        id="notifications-menu"
        class="menu notifications-menu"
      >
        <div
          v-for="noti in notifications"
          :key="noti.id"
          class="item"
          style="border-bottom: 1px solid rgba(0,0,0,0.05); "
        >
          <div class="ui comments">
            <div class="ui comment cur-point">
              <a
                :to="'/p/' + noti.username"
                class="avatar"
                style="background-color: transparent; width: 3.5em; margin-right: 8px;"
              >
                <img
                  :src="projectURL + noti.profile_img"
                  style="max-height: 45px;border-radius: 90px;"
                >
              </a>
              <component
                :is="noti.type"
                :notidata="noti.data"
                :timestamp="noti.timestamp"
                :username="noti.username"
                :size="size"
              />
            </div>
          </div>
        </div>
        <div class="item t-center">
          <router-link
            class="ui fluid button btn-inverted-color"
            to="/notification"
          >
            Alle ansehen
          </router-link>
        </div>
      </div>
    </div>
    <router-link
      v-else
      class="item"
      to="/notification"
    >
      <i class="bell icon" />
    </router-link>
  </div>
  <div
    v-for="noti in notifications"
    v-else
    :key="noti.id"
    class="pad-8"
    :class="{notiSeen: seenDelay(noti.seen)}"
  >
    <div class="ui segment no-border no-shadow grid-12 cur-point">
      <div class="col-sp-3 col-sd-1 row-sp-12 noti-avatar">
        <router-link :to="'/p/' + noti.username">
          <img
            class="ui image tiny circular"
            :src="projectURL + noti.profile_img"
          >
        </router-link>
      </div>
      <component
        :is="noti.type"
        :notidata="noti.data"
        :timestamp="noti.timestamp"
        :username="noti.username"
        :size="size"
      />
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {notificationStore} from "@/store/notificationStore";

import comment from '@/components/basic/notification/comment'
import commentOther from '@/components/basic/notification/commentOther'
import commentReaction from '@/components/basic/notification/commentReaction'
import friendsAccept from '@/components/basic/notification/friendsAccept'
import friends from '@/components/basic/notification/friends'
import mention from '@/components/basic/notification/mention'
import newFollower from '@/components/basic/notification/newFollower'
import newMatch from '@/components/basic/notification/newMatch'
import postReaction from '@/components/basic/notification/postReaction'
import postUnlocked from '@/components/basic/notification/postUnlocked'
import profileImageDeclined from '@/components/basic/notification/profileImageDeclined'
import stamp from '@/components/basic/notification/stamp'
import story from '@/components/basic/notification/story'
import verified from '@/components/basic/notification/verified'

export default {
  name: 'NotificationNav',
  components: {
    comment: comment,
    'comment-other': commentOther,
    stamp: stamp,
    story: story,
    'comment-reaction': commentReaction,
    'post-reaction': postReaction,
    'friend-accept': friendsAccept,
    friends: friends,
    'follow-update': newFollower,
    match: newMatch,
    mention: mention,
    verified: verified,
    'post-unlocked': postUnlocked,
    'profile-image-deleted': profileImageDeclined
  },
  props: ['size'],
  setup() {
    const auth = authStore()
    const user = userStore()
    const config = configStore()
    const notifications = notificationStore()

    return {auth, user, config, notifications}
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
