<template>
  <div class="sidebar">
    <div class="row no-gutters">
      <div class="col-auto align-self-center">
        <figure
          class="avatar avatar-50"
          style="background: transparent;"
        >
          <img
            class="ar-1-1"
            :src="config.projectURL + 'assets/images/user/' + user.profile_img"
          >
        </figure>
      </div>
      <div class="col pl-3 align-self-center">
        <p class="my-0">
          {{ user.firstname }}
        </p>
        <p class="text-mute my-0 small">
          {{ user.username }}
        </p>
      </div>
      <div class="col-auto align-self-center display-Mobile">
        <a
          class="btn btn-link text-white p-2 logout"
          @click="auth.logout"
        >
          <i class="material-icons">power_settings_new</i>
        </a>
      </div>
    </div>
    <div class="list-group main-menu my-4">
      <router-link
        active-class="active"
        to="/wall"
        class="list-group-item list-group-item-action"
      >
        <i class="material-icons">dashboard</i>Wall
      </router-link>
      <router-link
        active-class="active"
        to="/blog"
        class="list-group-item list-group-item-action"
      >
        <i class="material-icons">chrome_reader_mode</i>Magazin
      </router-link>
      <router-link
        active-class="active"
        to="/settings"
        class="list-group-item list-group-item-action"
      >
        <i class="material-icons">settings</i>Einstellungen
      </router-link>
      <router-link
        active-class="active"
        to="/home"
        class="list-group-item list-group-item-action"
      >
        <i class="material-icons">home</i>Startseite
      </router-link>

      <router-link
        v-if="user.account_type == 'admin' || user.account_type == 'supporter'"
        to="/teamdashboard"
        class="list-group-item list-group-item-action"
      >
        <i class="material-icons">view_day</i>Teambereich
      </router-link>
      <router-link
        v-if="user.account_type == 'admin' || user.account_type == 'redakteur'"
        to="/redaktion"
        class="list-group-item list-group-item-action"
      >
        <i class="material-icons">mode_edit</i>Redaktionsbereich
      </router-link>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: 'Sidebar',
  setup() {
    const auth = authStore()
    const user = userStore()
    const config = configStore()

    return {auth, user, config}
  },
  data () {
    return {
    }
  }
}
</script>

<style>
.sidebar .avatar img{
  border-radius: 90%;
}
/* Darkmode */
.dark .sidebar{
  background-color: #141414;
}
.sidebar{
  left: 0px;
}
</style>
