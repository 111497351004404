<template>
  <div id="Mobile-Top-Menu">
    <header class="header fixed-top header-fill">
      <nav class="navbar">
        <div>
          <button class="btn btn-link btn-44" @click="config.sidebar = !config.sidebar">
            <span class="icon material-icons">menu</span>
          </button>
        </div>
        <div>
          <router-link active-class="active" class="navbar-brand" to="/wall">
            <div class="t-center">
              <img class="logo_main" style="max-height: 45px; padding-top: 4px;" :src="logo">
            </div>
          </router-link>
        </div>
        <div id="nav-right">
          <router-link active-class="active" :to="'/p/' + user.username">
            <span v-if="socket.online" class="avatar avatar-30" style="background: transparent;">
              <img class="ar-1-1" :src="config.projectURL + 'assets/images/user/' + user.profile_img">
            </span>
            <div v-else class="ui segment" style="border: none; box-shadow: none;">
              <div class="ui active loader" />
            </div>
          </router-link>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {socketStore} from "@/store/socketStore";
import {notificationStore} from "@/store/notificationStore";
import {friendsStore} from "@/store/friendsStore";

export default {
  name: 'MobileTop',
  setup() {
    const auth = authStore()
    const user = userStore()
    const config = configStore()
    const socket = socketStore()
    const notifications = notificationStore()
    const friends = friendsStore()

    return {auth, user, config, socket, notifications, friends}
  },
  data () {
    return {
    }
  },
  computed: {
    logo () {
      return this.config.darkMode ? 'assets/images/dark-wurstsalat-beta-pride.png' : 'assets/images/wurstsalat-beta-pride.png'
    }
  }
}
</script>

<style>
.header.header-fill .navbar {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: 0;
}
#nav-right {
  margin-left: -30px;
}
</style>
