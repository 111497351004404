import { defineStore } from 'pinia'

export const configStore = defineStore('config', {
    state: () => ({
        projectURL: 'https://wurstsalat.20north.de/',
        darkMode: JSON.parse(localStorage.getItem('darkMode')) === 1,
        volume: 0,
        vibrate: JSON.parse(localStorage.getItem('vibrate')) === 1,
        loading: false,
        chatModal: false,
        sidebar: false,
        now: Date.now(),
        cordova: process.env.CORDOVA_PLATFORM ? process.env.CORDOVA_PLATFORM.toUpperCase() : false
    }),
    getters: {
        timer() {
            return this.now
        },
        calculateTimeString () {
            return (time) => {
                let remainTime = Math.floor((Date.now() - time) / 1000)
                let timeString = 'Vor ' + remainTime + ' Sek.'
                if (remainTime <= 10) timeString = 'Jetzt'
                if (remainTime > 59) {
                    remainTime = Math.floor(remainTime / 60)
                    timeString = 'Vor ' + remainTime + ' Min.'
                    if (remainTime > 59) {
                        remainTime = Math.floor(remainTime / 60)
                        timeString = 'Vor ' + remainTime + ' Std.'
                        if (remainTime > 23) {
                            remainTime = Math.floor(remainTime / 24)
                            timeString = 'Vor ' + remainTime + ' Tagen'
                            if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Tag'
                            if (remainTime > 6) {
                                const date = new Date(time)
                                timeString = 'Am ' + date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
                            }
                        }
                    }
                    return timeString
                }}
        }
    }
})