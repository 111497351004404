import * as Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import SuiVue from 'semantic-ui-vue';
import title from '@/mixins/title'
import './registerServiceWorker'
import { createPinia } from 'pinia'

const pinia = createPinia();
pinia.use(({ store }) => {
    store.$router = Vue.markRaw(router)
});

const vueProto = Vue.createApp(App).use(pinia).use(router).use(VueAxios, axios).use(SuiVue).mixin(title)
window.wurstsalat = vueProto.mount('#wurstApp')