import { defineStore } from 'pinia'
import axios from 'axios'
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {wallStore} from "@/store/wallStore";
import {friendsStore} from "@/store/friendsStore";
import {notificationStore} from "@/store/notificationStore";
import {socketStore} from "@/store/socketStore";
import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage} from "firebase/messaging";

const error = new ErrorEvent('doof')

export const authStore = defineStore('auth', {
    state: () => ({
        isAuth: JSON.parse(sessionStorage.getItem('sessionID')) != null,
        nodeID: null,
        keepID: JSON.parse(localStorage.getItem('keepID')),
        sessionID: JSON.parse(sessionStorage.getItem('sessionID')),
        lastActivity: 0,
        fcmConfig: {
            apiKey: 'AIzaSyAOdCtNTkf-3D-vOUdCjysqb8BzTRwQzAA',
            authDomain: 'wurstsalat-2011.firebaseapp.com',
            projectId: 'wurstsalat-2011',
            storageBucket: 'wurstsalat-2011.appspot.com',
            messagingSenderId: '310085250790',
            appId: '1:310085250790:web:d9fb88185e3e066c361527'
        },
        fcmToken: '',
        vapidKey: 'BEY_o89RUv2ZL1ViUuHFVoJV6oSEBCFbEiJcrFHYp83OHtePFME9Hx-t0jKkOK-iWCYuH19C8vNrMDl4P4Xvqjs'
    }),
    actions: {
        /**
         * login via vue
         * @param email {string}
         * @param password {string}
         * @param stayIn {bool}
         * @returns {Promise}
         */
        login(email, password, stayIn) {
            const _this = this
            return new Promise((resolve, reject) => {
                const fD = new FormData()
                fD.append('email', email)
                fD.append('password', password)
                fD.append('stayIn', stayIn)
                fD.append('type', 'login')

                const config = configStore()
                const user = userStore()
                axios.post(config.projectURL+'login/jslogin',fD)
                    .then((response) => {
                        const data = response.data
                        switch(response.status){
                            case 250:
                                user.$state = data
                                _this.nodeID = data.NodeID
                                _this.keepID = data.KeepID
                                _this.sessionID = data.SessionID
                                _this.isAuth = true
                                config.darkMode = data.darkmode
                                config.volume = data.volume

                                localStorage.setItem('keepID', JSON.stringify(_this.keepID))
                                sessionStorage.setItem('sessionID',JSON.stringify( _this.sessionID))

                                if (user.register_status === '0') {
                                    resolve(1)
                                } else {
                                    resolve(2)
                                }
                                break;

                            case 251:
                                _this.isAuth = false
                                reject(error);
                                break;

                            default:
                                _this.isAuth = false
                                reject(error);
                                break;
                        }
                    })
                    .catch((e)=> {
                        reject(4)
                    })
            })
        },
        /**
         * reset Password
         * @param email
         * @returns {Promise<unknown>}
         */
        resetPassword(email) {
            const _this = this
            return new Promise((resolve, reject) => {
                const fD = new FormData()
                fD.append('email', email)
                fD.append('type', 'passwordReset')

                const config = configStore()
                const user = userStore()
                axios.post(config.projectURL+'login/jslogin',fD)
                    .then((response) => {
                        resolve(response.status)
                        console.log(response)
                    })
                    .catch(()=> reject(error))
            })
        },
        /**
         * use key to reset password
         * @param key
         * @returns {Promise<unknown>}
         */
        restorePassword(key) {
            const _this = this
            return new Promise((resolve, reject) => {
                const fD = new FormData()
                fD.append('resetkey', key)
                fD.append('type', 'passwordRestore')

                const config = configStore()
                const user = userStore()
                axios.post(config.projectURL+'login/jslogin',fD)
                    .then((response) => {
                        resolve(response.status)
                    })
                    .catch(()=> reject(error))
            })
        },
        /**
         * register
         * @param formData
         */
        register(formData) {
            this.count++
        },
        /**
         *
         * @returns {Promise<unknown>}
         */
        logout() {
            const _this = this
            const user = userStore()
            const config = configStore()
            const wall = wallStore()
            const friends = friendsStore()
            const notifications = notificationStore()
            const socket = socketStore()

            axios.post(window.projectURL + 'login/logout')
                .then(function (response) {
                    console.log(response)
                    if (response.status === 250) {
                        localStorage.clear()
                        sessionStorage.clear()
                        localStorage.setItem('darkMode', JSON.stringify(config.darkMode))

                        user.$reset()
                        wall.$reset()
                        friends.$reset()
                        notifications.$reset()
                        socket.disconnect()
                        _this.$reset()

                        const cookies = document.cookie.split(';')

                        for (var i = 0; i < cookies.length; i++) {
                            const cookie = cookies[i]
                            const eqPos = cookie.indexOf('=')
                            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
                            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
                        }

                        try {
                            if (typeof cordova === 'undefined') {
                                navigator.serviceWorker.getRegistrations().then(function (registrations) {
                                    for (const registration of registrations) {
                                        registration.unregister()
                                    }
                                })
                            }
                        } catch (e) {  }

                        _this.$router.push('/')
                    }
                })
                .catch((e) => {
                    console.error('test', e)
                })
        },
        /**
         * Updates Status, checks Login
         * @returns {Promise}
         */
        update() {
            const _this = this
            const user = userStore()
            const config = configStore()
            return new Promise(function (resolve, reject) {
                const config = configStore()
                if (_this.isAuth && (_this.lastActivity + 60000) > Date.now()) {
                    resolve()
                } else if (_this.sessionID != null) {
                    _this.lastActivity = Date.now()

                    const fD = new FormData()
                    fD.append('SessionID', _this.sessionID)
                    axios.post(config.projectURL + 'dashboard/updateStatus', fD)
                        .then((response) => {
                            if (response.status == 250 || response.status == 220){
                                const data = response.data
                                user.$state = data
                                _this.nodeID = data.NodeID
                                _this.keepID = data.KeepID
                                config.darkMode = data.darkmode
                                config.volume = data.volume
                                _this.isAuth = true
                                resolve(response.status)
                            }else{
                                _this.sessionID = null
                                _this.cookieLogin()
                                    .then(() => {
                                        _this.isAuth = true
                                        resolve()
                                    })
                                    .catch((e) => {
                                        _this.isAuth = false
                                        reject(error)
                                    })
                            }
                        })
                        .catch((e) => {
                        })
                }else{
                    reject(error)
                }
            })
        },
        /**
         *
         * @returns {Promise<unknown>}
         */
        cookieLogin () {
            const _this = this
            return new Promise(function (resolve, reject) {
                const config = configStore()
                const user = userStore()
                try {
                    if (_this.keepID != null) {
                        const fD = new FormData()
                        fD.append('type', 'cookie')
                        fD.append('KeepID', _this.keepID)

                        axios.post(config.projectURL + 'login/jsLogin')
                            .then((response) => {
                                if (response.status == 250) {
                                    user.$state = data
                                    _this.nodeID = data.NodeID
                                    _this.keepID = data.KeepID
                                    _this.sessionID = data.SessionID
                                    _this.isAuth = true
                                    config.darkMode = data.darkmode
                                    config.volume = data.volume
                                    resolve(1);
                                } else {
                                    _this.$reset()
                                    user.$reset()
                                    reject(error)
                                }
                            })
                            .catch(() => {
                                _this.$reset()
                                user.$reset()
                                reject(error)
                            })
                    }
                } catch (e) {
                    reject(error)
                }
            })
        },
        /**
         * init firebase
         */
        firebaseSetup () {
            const config = configStore()
            const _this = this
            const isOniOS = config.cordova === 'IOS'

            FirebasePlugin.setAutoInitEnabled(true)
            const channel = {
                id: 'wurstsalat',
                sound: 'chat.mp3',
                vibration: true,
                light: true,
                lightColor: parseInt('6435C9FF', 16).toString(),
                importance: 4,
                badge: true,
                visibility: 1
            }
            FirebasePlugin.createChannel(channel)

            if (isOniOS) {
                // iOS device token
                FirebasePlugin.onApnsTokenReceived(apnsToken => {
                    console.log(apnsToken)
                    _this.sendToken(apnsToken)
                }, error => {
                    console.error(error)
                })
            } else {
                // Android device token
                FirebasePlugin.onTokenRefresh(fcmToken => {
                    console.log(fcmToken)
                    _this.sendToken(fcmToken)
                }, error => {
                    console.error(error)
                })
            }
        },
        /**
         * send token to backend
         * @param fcmToken
         */
        sendToken (fcmToken) {
            const _this = this
            const config = configStore()
            if (this.fcmToken !== fcmToken) {
                _this.fcmToken = fcmToken
                FirebasePlugin.getId(function (appInstanceId) {
                    const fD = new FormData()
                    fD.append('SessionID', _this.sessionID)
                    fD.append('name', config.cordova)
                    fD.append('data', JSON.stringify({ token: _this.fcmToken, id: appInstanceId }))
                    axios.post(config.projectURL + 'einstellungen/setpush', fD)
                        .catch(function (error) {
                            console.log(error)
                        })
                }, function (error) {
                    console.error(error)
                })
            }
        },
        /**
         * push app token to backend
         */
        pushToken () {
            const _this = this
            const config = configStore()
            try {
                if (typeof FirebasePlugin !== 'undefined') {
                    FirebasePlugin.setAutoInitEnabled(true)
                    const channel = {
                        id: 'wurstsalat',
                        sound: 'node_sound.mp3',
                        vibration: true,
                        light: true,
                        lightColor: parseInt('6435C9FF', 16).toString(),
                        importance: 4,
                        badge: true,
                        visibility: 1
                    }
                    FirebasePlugin.createChannel(channel)
                    FirebasePlugin.onTokenRefresh(function (fcmToken) {
                        if (_this.fcmToken !== fcmToken) {
                            _this.fcmToken = fcmToken
                            FirebasePlugin.getId(function (appInstanceId) {
                                const fD = new FormData()
                                fD.append('SessionID', _this.sessionID)
                                fD.append('name', config.cordova)
                                fD.append('data', JSON.stringify({ token: _this.fcmToken, id: appInstanceId }))
                                axios.post(config.projectURL + 'einstellungen/setpush', fD)
                                    .catch(function (error) {
                                        console.log(error)
                                    })
                            }, function (error) {
                                console.error(error)
                            })
                        }
                    }, function (error) {
                        console.error(error)
                    })
                }
            } catch (e) {}
        },
        /**
         * push web token to backend
         */
        pushWebToken () {
            const _this = this
            const config = configStore()
            try {
                if (typeof FirebasePlugin === 'undefined') {
                    let fbMessaging = null
                    try {
                        fbMessaging = getMessaging(initializeApp(firebaseConfig))
                    } catch (e) {
                        console.log(e)
                    }
                    getToken(fbMessaging, { vapidKey: _this.vapidKey }).then((currentToken) => {
                        if (currentToken) {
                            _this.fcmToken = currentToken
                            const fD = new FormData()
                            fD.append('SessionID', _this.sessionID)
                            fD.append('name', 'web')
                            fD.append('data', JSON.stringify({ token: currentToken }))
                            axios.post(config.projectURL + 'einstellungen/setpush', fD)
                                .catch(function (error) {
                                    console.log(error)
                                })
                            onMessage(fbMessaging, (payload) => {
                                console.log('Message received. ', payload)
                            })
                        } else {
                            console.log('No registration token available. Request permission to generate one.')
                        }
                    }).catch((err) => {
                        console.log('An error occurred while retrieving token. ', err)
                    })
                }
            } catch (e) {
                console.log(e)
            }
        },
    },
})