export function navbarSemantic () {
  $('.ui.checkbox').checkbox()
  $('.ui.accordion').accordion()
  $('.message .close').on('click', function () { $(this).closest('.message').transition('fade') })
  $('.menu .item').tab()
  $('.ui.modal').on('touchmove', function (event) { event.stopImmediatePropagation() })
  /* page load as iframe */
  if (self !== top) {
    $('body').addClass('iframe')
  } else {
    $('body').removeClass('iframe')
  }

  /* sidebar active menu open */
  $('.menu-btn').on('click', function (e) {
    e.stopPropagation()
    if ($('body').hasClass('sidemenu-open')) {
      $('body').removeClass('sidemenu-open')

      if ($('body').hasClass('reveal-sidebar')) {
        $('body').removeClass('reveal-sidebar')
      }
    } else {
      $('body').addClass('sidemenu-open')
      if ($('.sidebar').hasClass('reveal-sidebar')) {
        $('body').addClass('reveal-sidebar')
      }
    }
    /* active link url */
    var url = window.location
    $('.header .navbar-nav a').removeClass('active').parent().removeClass('active').closest('.nav-item').removeClass('active')
    $('.header .navbar-nav a').filter(function () {
      return this.href === url
    }).addClass('active').parent().addClass('active').closest('.nav-item').addClass('active')
  })

  document.addEventListener('click', function (event) {
    if (document.querySelector('body').classList.contains('sidemenu-open')) {
      var isClickInside = document.querySelector('div.sidebar').contains(event.target)

      if (!isClickInside) {
        document.querySelector('.menu-btn').click()
      }
    }
  })
}
