<template>
  <footer id="publicfooter">
    <div class="content-1200 grid-16 pad-16">
      <div class="col-sp-16 col-tb-8 col-sd-4 row-sp-16 pad-8">
        <h5>Wurstsalat unterstützen</h5>
        <ul>
          <li>
            <a
              href="https://www.pridedigital.de"
              target="_blank"
            >
              Werben auf Wurstsalat
            </a>
          </li>
          <li>
            <a
              href="http://wurstsalat.shop/"
              target="_blank"
            >
              Community Shop
            </a>
          </li>
        </ul>
      </div>
      <div class="col-sp-16 col-tb-8 col-sd-4 row-sp-16 pad-8">
        <h5>Plattform</h5>
        <ul>
          <!--
          <li>
              <a href="<?=URL?>vereine">
                  Wurstsalat für Vereine
              </a>
          </li>
          <li>
              <a href="<?=URL?>unternehmen">
                  Wurstsalat für Unternehmen
              </a>
          </li>
          <li>
              < href="<?=URL?>creator">
                  Wurstsalat für Creator
              </a>
          </li>
          -->
          <li>
            <router-link to="/eltern">
              Information für Eltern
            </router-link>
          </li>
        </ul>
      </div>
      <div class="col-sp-16 col-tb-8 col-sd-4 row-sp-16 pad-8">
        <h5>Weitere Links</h5>
        <ul>
          <li>
            <router-link to="/kontakt">
              Kontakt
            </router-link>
          </li>
          <li>
            <router-link to="/faq">
              FAQ
            </router-link>
          </li>
          <li>
            <router-link to="/about#Team">
              Unser Team
            </router-link>
          </li>
        </ul>
      </div>
      <div class="col-sp-16 col-tb-8 col-sd-4 row-sp-16 pad-8">
        <h5>Social Media</h5>
        <ul>
          <li>
            <a
              href="https://www.instagram.com/wurstsalat.app"
              target="_blank"
            >
              <i class="instagram icon" /> Instagram
            </a>
          </li>
          <li>
            <a
              href="https://www.twitter.com/wurstsalatapp"
              target="_blank"
            >
              <i class="twitter icon" /> Twitter
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div id="FooterBottom">
      <div class="content-1200 t-center">
        <div>
          <p>
            Copyright &copy; 2021 <router-link to="/home">
              Wurstsalat&#174;
            </router-link> | All rights reserved.
          </p>
        </div>
        <router-link to="/impressum">
          Impressum
        </router-link> |
        <router-link to="/datenschutz">
          Datenschutz
        </router-link> |
        <router-link to="/nutzungsbedingungen">
          Nutzungsbedigungen
        </router-link>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'PublicFooter'
}
</script>

<style scoped>
footer#publicfooter{
  background-color: #f7f9fd;
  width: 100%;
}
footer#publicfooter h5{
  text-align: left;
  position: relative;
  color: rgba(0,0,0,0.8);
  font-size: 1.2em;
  padding-bottom: 16px;
  padding-top: 32px;
}
footer#publicfooter h5:after{
  display: block;
  content: '';
  width: 40px;
  height: 3px;
  bottom: -0.5px;
  position: absolute;
  background-color: #390055;
}
footer#publicfooter ul li{
  list-style: none;
  padding: 2px 0;
}
footer#publicfooter ul li a{
  color: rgba(0,0,0,0.4);
  font-size: 1em;
  font-weight: 500;
  transition: 0.3s;
}
footer#publicfooter ul li a:hover{
  color: #390055;
}
footer#publicfooter #FooterBottom{
  margin-top: 32px;
  background-color: rgba(0,0,0,0.1);
  width: 100%;
  padding: 24px;
  border-top: 1px solid rgba(0,0,0,0.1);
  color: rgba(0,0,0,0.6);
}
footer#publicfooter a{
  color: #4B0072;
}
footer#publicfooter #FooterBottom p{
  font-weight: 400;
  color: rgba(0,0,0,0.6);
}
/* Darkmode */
.dark footer#publicfooter{background-color: #1B1C1D;}
.dark footer#publicfooter #FooterBottom p{color: rgba(255,255,255,0.6);}
.dark footer#publicfooter a{color: #4bb7c3;}
.dark footer#publicfooter h5:after{background-color: #4bb7c3;}
.dark footer#publicfooter h5{color: rgba(255,255,255,0.8);}
</style>
