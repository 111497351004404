<template>
  <router-link
    v-if="size == 'small'"
    class="content"
    :to="'/post/' + notidata.post"
  >
    <a class="author">Neue Reaktion</a>
    <div class="text">
      {{ username }} hat auf deinen Post reagiert
      <img :src="projectURL + 'assets/images/likes/' + notidata.reaction">
    </div>
    <div
      class="metadata t-right"
      style="display: block"
    >
      <div class="date">
        {{ date }}
      </div>
    </div>
  </router-link>
  <router-link
    v-else
    class="col-sp-9 col-sd-11 row-sp-12 pad-16"
    :to="'/post/' + notidata.post"
  >
    <h4 class="ui header">
      Neue Reaktion
      <div class="sub header">
        {{ username }} hat auf deinen Post reagiert
        <img :src="projectURL + 'assets/images/likes/' + notidata.reaction">
      </div>
    </h4>
    <div class="t-right">
      {{ date }}
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'PostReaction',
  props: ['notidata', 'timestamp', 'username', 'size'],
  data () {
    return {
      now: Date.now(),
      time: this.getTime(),
      projectURL: window.projectURL
    }
  },
  computed: {
    date () {
      return this.$parent.calculateTime(this.time, this.now)
    }
  },
  created () {
    setInterval(this.getNow, 5000)
  },
  mounted () {
    $('#notis').dropdown()
  },
  methods: {
    getNow: function () {
      this.now = Date.now()
    },
    getTime () {
      const arr = this.timestamp.split(/[- :.T]/)
      return new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]).getTime()
    }
  }
}
</script>

<style scoped>
h4.ui.header .sub.header img{
  max-width: 20px;
}
</style>
