<template>
  <div id="FakeCheckModal" class="ui modal">
    <div class="header">
      <i class="check circle blue icon" /> Wurstsalat Profil verifizieren
    </div>
    <div class="content">
      <div class="grid-12">
        <div class="col-sp-12 col-sd-4 row-sp-12 pad-8">
          <img src="assets/images/julian-verify.jpg" class="responsive-img">
        </div>
        <div class="col-sp-12 col-sd-8 row-sp-12 pad-8">
          <div class="description" style="flex: unset;">
            <h4 class="ui header">
              So funktionierts:
            </h4>
            <ol class="ui list">
              <li>
                Schreibe Handschriftlich auf einen Blatt Papier deinen Verifizierungscode:
                <b id="fakeID" />
              </li>
              <li>Mache ein Foto von dir auf dem du den Zettel sichtbar in der Hand hälst und dein Gesicht klar erkennbar ist.</li>
              <li>Lade das Foto hoch und habe etwas geduld.</li>
            </ol>
            <p>Nach dem wir das Bild geprüft haben, werden wir dein Profil als verifiziert kennzeichnen.</p>
            <div class="pad-8" />
            <form class="ui form editProfileForm" method="post" action="profile/verifyProfile">
              <div class="field">
                <label>Bilddatei:</label>
                <input type="file" name="verifyimage" accept="image/jpeg, image/png" required>
              </div>
              <div class="t-right">
                <button type="submit" class="ui button btn-color-1">
                  Abschicken
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div id="verifyloader" style="top: 58px!important;height: calc(100% - 111px);z-index: 5; display: none;" class="ui active inverted dimmer">
      <div class="ui text loader">
        Bitte warten
      </div>
    </div>
  </div>
  <div v-if="own" id="cropperModal" class="ui tiny modal">
    <div class="page-profile">
      <div class="content-1000 grid-12 pad-8">
        <div class="col-sp-12 row-sp-12 pad-16 t-center">
          <h3 class="ui header">
            Schneide dein Bild zu
            <div class="sub header">
              Quadratisch, Praktisch... ähm Rund? <img style="max-width: 32px;" src="assets/images/emoticons/like.png">
            </div>
          </h3>
          <p>Makiere den Bereich auf dem Bild, welches du als Profilbild nutzen möchtest und klicke danach auf Speichern.</p>
        </div>
        <div class="col-sp-12 row-sp-12 pad-16">
          <div>
            <img id="target" class="responsive-img" style="display: block; width: 100%;" :src="config.projectURL + 'assets/images/temp/' + tempImg">
          </div>
          <form id="cropForm" @submit.prevent="crop">
            <input id="x" type="hidden" name="x">
            <input id="y" type="hidden" name="y">
            <input id="w" type="hidden" name="w">
            <input id="h" type="hidden" name="h">
            <input id="ow" type="hidden" name="ow">
            <input id="oh" type="hidden" name="oh">
            <input id="cropperFile" type="hidden" name="file" :value="tempImg">
            <div class="pad-8 t-center">
              <button id="savecrop" type="submit" class="ui fluid purple disabled button avatar-save">
                Speichern
              </button>
            </div>
          </form>
          <div class="pad-8 t-center">
            <a onclick="$('#cropperModal').modal('hide');" class="color-2">Abbrechen</a>
          </div>
        </div>
        <div class="pad-16" />
      </div>
    </div>
  </div>
  <div v-if="status == 'ok'" id="UserProfile" class="spaceTop">
    <div id="ptrest" />
    <div class="content-800">
      <div class="grid-16">
        <div class="col-sp-16 row-sp-16">
          <div id="ProfileNav">
            <div class="grid-12">
              <div class="col-sp-6 row-sp-12">
                <a @click="$router.back();">
                  <i class="icon left arrow" />
                </a>
              </div>
              <div v-if="!own" class="col-sp-6 row-sp-12 t-right">
                <div class="ui dropdown" tabindex="0">
                  <i class="ellipsis vertical icon" />
                  <div class="menu transition hidden" tabindex="-1">
                    <div id="reportProfile" class="item">
                      <i class="flag icon" /> Profil Melden
                    </div>
                    <div class="item">
                      <form method="post" class="editProfileForm" action="/profile/blockUser">
                        <input type="hidden" name="u" :value="profile.id">
                        <button style="border: none; background: transparent;">
                          <i class="ban icon" /> Benutzer blockieren
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Mobile -->
      <div id="ptrPull" class="grid-16 pad-16">
        <div class="col-sp-16 row-sp-16">
          <section id="ProfileImage">
            <div class="profile-image-edit-cta">
              <button v-if="own" onclick="$('#EditProfileImage.ui.modal').modal('show');" class="circular inverted ui basic mini button icon">
                <i class="icon edit" />
              </button>
            </div>
            <img v-if="profile.profile_img" :src="config.projectURL + 'assets/images/user/' + profile.profile_img" class="ar-1-1">
          </section>
          <section id="ProfileTitle">
            <h2 class="ui header">
              {{ profile.username }}, {{ profile.age }}
              <span v-if="profile.verify == 1" style="font-size: 0.7em; margin-right: -16px;" title="Der User ist von Wurstsalat verifiziert"><i class="check circle blue icon" /></span>
              <div class="sub header">
                <i class="icon map marker alternate" /> {{ profile.cname }}
                <a v-if="profile.online == 1" class="ui basic mini label OnlineStatus">
                  <i class="circle green icon" />
                  Online
                </a>
                <a v-if="profile.lastActive != 0" class="ui basic mini label OfflineStatus">
                  <i class="circle grey icon" />
                  {{ profile.lastActive }}
                </a>
              </div>
            </h2>
            <section id="ProfileCTAs">
              <div v-if="!own" class="grid-12">
                <div class="col-sp-4 row-sp-12" onclick="$('#StampModal').modal('show');">
                  <div class="profile-cta-img stampImg">
                    <img v-if="!ownCrush" src="assets/images/emoticons/like.png">
                    <img v-else :src="'assets/images/komplimente/' + ownCrush">
                  </div>
                  <span>Stamp</span>
                </div>
                <router-link class="col-sp-4 row-sp-12" :to="'/messages/' + profile.username">
                  <div class="profile-cta-img">
                    <img src="assets/images/emoticons/chat.png">
                  </div>
                  <span>Chat</span>
                </router-link>
                <div class="col-sp-4 row-sp-12" v-if="!own && friendCheck == false" @click="addFriend">
                  <div class="profile-cta-img">
                    <img src="assets/images/emoticons/hi.png">
                  </div>
                  <span>Hinzufügen</span>
                </div>
                <div class="col-sp-4 row-sp-12" v-else-if="!own && friendCheck.accept == 0 && friendCheck.user_1 == user.id" @click="declineFriend(friendCheck.id)">
                  <div class="profile-cta-img">
                    <img src="assets/images/emoticons/hi.png">
                  </div>
                  <span>Zurückziehen</span>
                </div>
                <div class="col-sp-4 row-sp-12" v-else-if="!own && friendCheck.accept == 0" @click="acceptFriend(friendCheck.id)">
                  <div class="profile-cta-img">
                    <img src="assets/images/emoticons/hi.png">
                  </div>
                  <span>Annehmen</span>
                </div>
                <div class="col-sp-4 row-sp-12" v-else-if="!own" @click="declineFriend(friendCheck.id)">
                  <div class="profile-cta-img">
                    <img src="assets/images/emoticons/hi.png">
                  </div>
                  <span>Entfernen</span>
                </div>
              </div>
              <div class="action-buttons">
                <div class="pad-4" />
                <router-link v-if="own" class="ui button icon fluid labeled btn-color-1" to="/ep">
                  <i class="user edit icon" /> Profil bearbeiten
                </router-link>
              </div>
            </section>
          </section>
          <div class="pad-8" />
          <section id="ProfileDescription">
            <div class="description-content">
              <h6 class="t-center color-white">
                Profilbeschreibung
              </h6>
              <p class="t-center" v-if="notNull(profile.profile_description)">
                {{ profile.profile_description }}
              </p>
            </div>
          </section>
          <section id="ProfileInterests">
            <div id="interestsSame">
              <a v-for="(interest, index) in interestsFiltered" :key="index" class="ui basic label" :class="{active: ownInterests.includes(interest.id)}">
                {{ interest.channel_title }}
                <img class="ui mini image" :src="config.projectURL + '/assets/images/channels/' + interest.channel_img">
              </a>
            </div>
          </section>
        </div>
      </div>
      <div class="pad-16">
          <div class="ui secondary pointing menu" style="margin-bottom: 0px;">
            <a id="InfoTabMenu" class="item" :class="{active: tab == 'info'}" @click="tab = 'info'">
              Infos
            </a>
            <a id="FragebogenTabMenu" class="item" :class="{active: tab == 'fragebogen'}" @click="tab = 'fragebogen'">
              Fragebogen
            </a>
            <a id="GalerieTabMenu" class="item" :class="{active: tab == 'galerie'}" @click="tab = 'galerie'">
              Galerie
            </a>
          </div>
          <div v-if="tab == 'info'" id="InfoTab">
            <section id="ProfileInfo">
              <div class="grid-12">
                <div class="col-sp-12 row-sp-12">
                    <h4 class="t-center pad-16">Über mich</h4>
                </div>
                <div class="col-sp-6 col-sd-4 row-sp-12">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">
                          Vorname
                        </div>
                        {{ profile.firstname }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="details.pronome" class="col-sp-6 col-sd-4 row-sp-12">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">
                          Pronomen
                        </div>
                        {{ details.pronome }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="details.gender" class="col-sp-6 col-sd-4 row-sp-12">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">
                          Geschlecht
                        </div>
                        {{ details.gender }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="details.languages" class="col-sp-6 col-sd-4 row-sp-12">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">
                          Ich spreche
                        </div>
                        {{ details.languages.replace(',',', ') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="details.working_as" class="col-sp-6 col-sd-4 row-sp-12">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">
                          Tätigkeit
                        </div>
                        {{ details.working_as }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="details.lifestyle" class="col-sp-6 col-sd-4 row-sp-12">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">
                          Lebensweise
                        </div>
                        {{ details.lifestyle }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid-12">
                <div v-if="profile.cname" class="col-sp-6 col-sd-4 row-sp-12">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">
                          Wohnort
                        </div>
                        {{ profile.cname }} <span v-if="!own">({{ profile.description }}km)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="details.wohn_situation" class="col-sp-6 col-sd-4 row-sp-12">
                  <div class="ui horizontal list">
                    <div class="item">
                      <div class="content">
                        <div class="ui sub header">
                          Wohnsituation
                        </div>
                        {{ details.wohn_situation }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div v-if="tab == 'fragebogen'" id="FragebogenTab">
            <section id="ProfileFragebogen" class="grid-12">
              <div class="col-sp-12 row-sp-12">
                <h4 class="t-center pad-16">Fragebogen</h4>
              </div>
              <div v-if="notNull(fragebogen.about_me)" class="col-sp-12 row-sp-12">
                <div class="ui horizontal list">
                  <div class="item">
                    <div class="content">
                      Über mich
                      <div class="ui sub header">
                        {{ fragebogen.about_me }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pad-16" />
              </div>
              <div
                v-if="notNull(fragebogen.i_like)"
                class="col-sp-12 col-tb-6 row-sp-12"
              >
                <div class="ui horizontal list">
                  <div class="item">
                    <img
                      class="ui mini image"
                      src="assets/images/emoticons/like.png"
                    >
                    <div class="content">
                      <div class="ui sub header">
                        Ich mag
                      </div>
                      {{ fragebogen.i_like }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="notNull(fragebogen.i_hate)"
                class="col-sp-12 col-tb-6 row-sp-12"
              >
                <div class="ui horizontal list">
                  <div class="item">
                    <img
                      class="ui mini image"
                      src="assets/images/emoticons/dislike.png"
                    >
                    <div class="content">
                      <div class="ui sub header">
                        Ich mag nicht
                      </div>
                      {{ fragebogen.i_hate }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="notNull(fragebogen.favorite_food)"
                class="col-sp-12 col-tb-6 row-sp-12"
              >
                <div class="ui horizontal list">
                  <div class="item">
                    <img
                      class="ui mini image"
                      src="assets/images/emoticons/teller.png"
                    >
                    <div class="content">
                      <div class="ui sub header">
                        Lieblingsessen
                      </div>
                      {{ fragebogen.favorite_food }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="notNull(fragebogen.favorite_music)"
                class="col-sp-12 col-tb-6 row-sp-12"
              >
                <div class="ui horizontal list">
                  <div class="item">
                    <img
                      class="ui mini image"
                      src="assets/images/emoticons/noten.png"
                    >
                    <div class="content">
                      <div class="ui sub header">
                        Lieblingsmusik
                      </div>
                      {{ fragebogen.favorite_music }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="notNull(fragebogen.others_text)"
                class="col-sp-12 row-sp-12"
              >
                <div class="ui horizontal list">
                  <div class="item">
                    <img
                      class="ui mini image"
                      src="assets/images/emoticons/lampe.png"
                    >
                    <div class="content">
                      <div class="ui sub header">
                        Was ich sonst noch sagen möchte
                      </div>
                      {{ fragebogen.others_text }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="notNull(fragebogen.i_am_1) || notNull(fragebogen.i_am_2) || notNull(fragebogen.i_am_3) || notNull(fragebogen.i_am_4) || notNull(fragebogen.i_am_5)"
                class="col-sp-12 row-sp-12"
              >
                <div class="pad-16" />
                <h5 class="ui header">
                  Ich bin
                </h5>
                <div
                  v-if="notNull(fragebogen.i_am_1)"
                  class="grid-12"
                >
                  <div class="col-sp-6 row-sp-4">
                    Schüchtern
                  </div>
                  <div class="col-sp-6 row-sp-4 t-right">
                    Forsch
                  </div>
                  <div class="col-sp-12 row-sp-8">
                    <div class="ui violet progress">
                      <div
                        class="bar bg-color-1"
                        :style="{width: fragebogen.i_am_1 + '%'}"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="notNull(fragebogen.i_am_2)"
                  class="grid-12"
                >
                  <div class="col-sp-6 row-sp-4">
                    Häuslich
                  </div>
                  <div class="col-sp-6 row-sp-4 t-right">
                    Partygänger
                  </div>
                  <div class="col-sp-12 row-sp-8">
                    <div class="ui violet progress">
                      <div
                        class="bar bg-color-1"
                        :style="{width: fragebogen.i_am_2 + '%'}"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="notNull(fragebogen.i_am_3)"
                  class="grid-12"
                >
                  <div class="col-sp-6 row-sp-4">
                    Unkreativ
                  </div>
                  <div class="col-sp-6 row-sp-4 t-right">
                    Kreativ
                  </div>
                  <div class="col-sp-12 row-sp-8">
                    <div class="ui violet progress">
                      <div
                        class="bar bg-color-1"
                        :style="{width: fragebogen.i_am_3 + '%'}"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="notNull(fragebogen.i_am_4)"
                  class="grid-12"
                >
                  <div class="col-sp-6 row-sp-4">
                    Gemütlich
                  </div>
                  <div class="col-sp-6 row-sp-4 t-right">
                    Sportlich
                  </div>
                  <div class="col-sp-12 row-sp-8">
                    <div class="ui violet progress">
                      <div
                        class="bar bg-color-1"
                        :style="{width: fragebogen.i_am_4 + '%'}"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="notNull(fragebogen.i_am_5)"
                  class="grid-12"
                >
                  <div class="col-sp-6 row-sp-4">
                    Ordentlich
                  </div>
                  <div class="col-sp-6 row-sp-4 t-right">
                    Chaot
                  </div>
                  <div class="col-sp-12 row-sp-8">
                    <div class="ui violet progress">
                      <div
                        class="bar bg-color-1"
                        :style="{width: fragebogen.i_am_5 + '%'}"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div v-if="tab == 'galerie'" id="GalerieTab">
            <section id="ProfileGalerie" class="grid-12">
              <div class="col-sp-12 row-sp-12 t-right">
                <button v-if="own" class="ui basic icon button" onclick="$('#UploadImage.ui.modal').modal('show');">
                  <i class="upload icon" /> Bild hochladen
                </button>
                <div class="pad-8" />
              </div>
              <div id="GaleryGrid" class="col-sp-12 row-sp-12">
                <figure v-for="(img, index) in galery" :key="index">
                  <img :id="'galery_' + img.id" class="galeryPost" :src="config.projectURL + '/assets/images/posts/' + img.image" @click="window.wall.loadSingle(img.id)">
                </figure>
              </div>
            </section>
          </div>
          <emojimenu />
          <div id="resetWall" class="t-right" style="display:none">
            <button class="circular ui basic button icon labeled" type="button" onclick="window.wall.resetWall('profile');">
              <i class="cancel icon" />Wall zurücksetzen
            </button>
            <div class="pad-8" />
          </div>
          <div id="posts">
            <div id="Postloader" class="rainbowloader">
              <div class="loader-inner">
                <div class="loader-line-wrap">
                  <div class="loader-line" />
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line" />
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line" />
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line" />
                </div>
                <div class="loader-line-wrap">
                  <div class="loader-line" />
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  <!-- EditProfileImage Modal -->
  <div v-if="own" id="pbloader" style="z-index: 9999; display: none;" class="ui active inverted dimmer">
    <div class="ui text loader">
      Bitte warten
    </div>
  </div>
  <a v-if="user.account_type == 'admin'" class="adminButton circular ui icon button btn-color-2" :href="'https://wurstsalat.20north.de/benutzerverwaltung?page=edit&uid=' + profile.id" target="_blank">
    <i class="icon user adminIcon" />
  </a>
  <div v-if="own" id="EditProfileImage" class="ui tiny modal">
    <i class="close icon report" />
    <div class="header t-center">
      Profilbild
    </div>
    <div class="scrolling content">
      <div class="grid-16">
        <div class="col-sp-16 col-tb-4 row-sp-16 pad-4">
          <div class="ui small centered image circular">
            <img class="ar-1-1" data-src="profile-profile_img">
          </div>
        </div>
        <div class="col-sp-16 col-tb-12 row-sp-16 pad-4">
          <form id="profileImgForm" class="ui form" @submit.prevent="profileImgUpload">
            <div class="field">
              <label>Datei auswählen:</label>
              <input id="datei" type="file" value="" name="postimage" accept="image/png, image/jpg, image/jpeg" @change="profileImgUpload">
            </div>
          </form>
          <div class="pad-8" />
          <button class="ui button labeled icon mini fluid" @click="deleteProfileImg()">
            <i class="trash icon" /> Profilbild löschen
          </button>
        </div>
      </div>
    </div>
    <div class="pad-16">
      <div class="ui fluid btn-color-1 right labeled icon button" onclick="document.getElementById('editProfileDescriptionForm').submit();">
        Speichern
        <i class="save icon" />
      </div>
    </div>
  </div>
  <!-- Imageupload Modal -->
  <div id="UploadImage" class="ui tiny modal">
    <i class="close icon" />
    <div class="header t-center">
      Bild hochladen
    </div>
    <div class="content scrolling">
      <form id="postImageForm" class="ui form" action="profile/uploadImage" enctype="multipart/form-data">
        <div id="post-image-upload" style="display: block;">
          <input id="postimage" type="file" accept="image/png, image/jpeg, image/jpg" name="postimage" required style="display: none;">
          <label for="postimage" class="ui button fluid" type="button">
            <i class="camera icon" /> Bild auswählen
          </label>
        </div>
        <div id="preview-col" class="pimg pad-8" style="display: none;">
          <img id="output_image" class="responsive-img">
        </div>
        <div class="pad-8" />
        <div class="field">
          <label>Bildbeschreibung</label>
          <textarea id="postText" rows="4" name="postcontent" placeholder="Bildbeschreibung"/>
        </div>
        <div class="t-right">
          <button id="UploadtoGalerybtn" class="ui fluid right labeled icon button btn-color-1" type="submit" onclick="UploadtoGalery();">
            Zur Galerie hinzufügen
            <i class="upload icon" />
          </button>
        </div>
      </form>
    </div>
  </div>

  <div
    v-if="status == 'iBlock'"
    class="page-profile iBlock spaceTop"
  >
    <div class="content-1000 pad-40">
      <div class="ui segment t-center">
        <h2 class="ui header">
          Ups!
          <div class="sub header">
            Du hast dieses Profil blockiert.
          </div>
        </h2>
        <form
          method="post"
          class="editProfileForm"
          action="profile/entblockUser"
        >
          <input
            type="hidden"
            name="u"
            :value="profile.username"
          >
          <input
            type="hidden"
            name="uid"
            :value="profile.id"
          >
          <button class="ui button btn-color-1">
            <i class="check icon" /> Benutzer freigeben
          </button>
        </form>
        <div class="pad-8" />
        <a
          class="ui button btn-color-1"
          @click="$router.back()"
        >Zurück</a>
      </div>
    </div>
  </div>

  <div
    v-if="status == 'otherBlock'"
    class="page-profile otherBlock spaceTop"
  >
    <div class="content-1000 pad-40">
      <div class="ui segment t-center">
        <h2 class="ui header">
          Ups!
          <div class="sub header">
            Du wurdest von diesem Nutzer blockiert.
          </div>
        </h2>
        <a
          class="ui button btn-color-1"
          @click="$router.back()"
        >Zurück</a>
      </div>
    </div>
  </div>

  <div
    v-if="status == 'notFound'"
    class="page-profile notFound spaceTop"
  >
    <div class="content-1000 pad-40">
      <div class="ui segment t-center">
        <h2 class="ui header">
          Ups!
          <div class="sub header">
            Das Profil konnte nicht gefunden werden.
          </div>
        </h2>
        <a
          class="ui button btn-color-1"
          @click="$router.back()"
        >Zurück</a>
      </div>
    </div>
  </div>
</template>

<script>
import Emojimenu from '@/components/plugins/emojimenu'
import { decode } from '@/modules/main'
import axios from 'axios'
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {authStore} from "@/store/authStore";

export default {
  name: 'Profile',
  components: {
    Emojimenu
  },
  inject: ['user'],
  beforeRouteUpdate (to, from) {
    this.username = to.params.username
    document.title = 'Wurstsalat | ' + this.username
    this.loadProfile()
  },
  setup() {
    const user = userStore()
    const config = configStore()
    const auth = authStore()

    return { user, config, auth }
  },
  data () {
    return {
      username: '',
      status: null,
      profile: [],
      details: [],
      partnerDetails: [],
      allFriends: [],
      visitors: [],
      interests: [],
      otherInterests: [],
      ownInterests: [],
      fragebogen: [],
      friendCheck: [],
      galery: [],
      tab: 'info',
      tempImg: false
    }
  },
  computed: {
    own () {
      try {
        return this.profile.id === this.user.user_id
      } catch (e) {
        return false
      }
    },
    interestsFiltered () {
      const _this = this
      const filtered = []
      return this.interests.filter(el => el.channel_type === 'channel' && Object.values(_this.otherInterests).some(function (k) { return k.id === el.id }))
    }
  },
  mounted () {
    this.username = this.$route.params.username
    document.title = 'Wurstsalat | ' + this.username
    this.loadProfile()
  },
  methods: {
    loadProfile () {
      const _this = this
      const fD = new FormData()
      fD.append('username', this.username)
      fD.append('SessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + 'profile/indexjs', fD)
        .then(function (response) {
          if (response.status === 250) {
            _this.status = 'ok'
          } else if (response.status === 204) {
            _this.status = 'notFound'
          } else if (response.status === 205) {
            _this.status = 'otherBlock'
          } else if (response.status === 206) {
            _this.status = 'iBlock'
          }
          const data = response.data
          _this.profile = data.profile
          _this.details = data.details
          _this.partnerDetails = data.partnerDetails
          _this.visitors = data.visitors
          _this.interests = data.interests
          _this.otherInterests = data.otherInterests
          _this.ownInterests = data.ownInterests.split(',')
          _this.fragebogen = data.fragebogen
          _this.friendCheck = data.friendCheck
          _this.galery = data.galery
          _this.profile.profile_description = decode(_this.profile.profile_description)
          _this.fragebogen.about_me = decode(_this.fragebogen.about_me)
          _this.fragebogen.i_like = decode(_this.fragebogen.i_like)
          _this.fragebogen.i_hate = decode(_this.fragebogen.i_hate)
          _this.fragebogen.others_text = decode(_this.fragebogen.others_text)
          _this.fragebogen.favorite_music = decode(_this.fragebogen.favorite_music)
          _this.fragebogen.favorite_food = decode(_this.fragebogen.favorite_food)

          _this.tab = 'info'

          _this.config.loading = false
        })
        .catch(function (e) {
          console.error(e)
          /*_this.auth.update().then(_this.loadProfile).catch(function () {
            _this.$router.push('/login')
          })*/
        })
    },
    notNull (obj) {
      return obj !== null && obj !== undefined && obj !== 'null' && obj !== 'undefined'
    },
    setCrush (cid) {
      const _this = this
      const formdata = new FormData()
      formdata.append('SessionID', this.auth.sessionID)
      formdata.append('cid', cid)
      formdata.append('pid', this.profile.id)
      this.axios.post(this.config.projectURL + 'profile/setCrush', formdata)
        .then(function (response) {
          if (response.status === 250) {
            console.log(response.data)
            _this.ownCrush = response.data.crush
            $('#StampModal').modal('hide')
          } else if (response.status === 299) {
            cookieLogin().then(function () { _this.setCrush(cid) }).catch(function () { _this.$router.push('/login') })
          } else {
            $('#StampModal').modal('hide')
          }
        })
    },
    deleteProfileImg () {
      const _this = this
      const formdata = new FormData()
      formdata.append('postkey', this.auth.sessionID)
      formdata.append('SessionID', this.auth.sessionID)
      this.axios.post(_this.config.projectURL + 'profile/deleteProfileImage', formdata)
        .then(function (response) {
          if (response.status === 250) {
            try {
              _this.user.profile_img = 'avatar_default.png'
              _this.profile.profile_img = 'avatar_default.png'
            } catch (e) {}
          } else if (response.status === 299) {
            _this.auth.update().then(_this.deleteProfileImg).catch(window.wurstsalat.$router.push('/login'))
          }
        })
    },
    profileImgUpload () {
      const _this = this
      this.config.loading = true
      const formdata = new FormData(document.getElementById('profileImgForm'))
      formdata.append('postkey', this.auth.sessionID)
      formdata.append('SessionID', this.auth.sessionID)
      $('#EditProfileImage').modal('hide')
      this.axios.post(this.config.projectURL + 'p/uploadProfileImage', formdata, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(function (response) {
          console.log(response)
          if (response.status === 250) {
            try {
              _this.tempImg = response.data
              setTimeout(function () {
                _this.cropper()
              }, 10)
              _this.config.loading = false
              $('#cropperModal').modal('show')
            } catch (e) {
              console.log(e)
            }
          } else if (response.status === 299) {
            _this.auth.update().then(() => { $(this).submit() }).catch(window.wurstsalat.$router.push('/login'))
          }
        })
    },
    cropper () {
      const _this = this
      const Cropper = window.Cropper
      const minCroppedWidth = 100
      const minCroppedHeight = 100

      const cropper = new Cropper(document.getElementById('target'), {
        aspectRatio: 1 / 1,
        zoomable: false,
        crop (event) {
          if (
            event.detail.width < minCroppedWidth ||
            event.detail.height < minCroppedHeight
          ) {
            cropper.setData({
              width: minCroppedWidth,
              height: minCroppedHeight
            })
          }
          document.getElementById('savecrop').classList.remove('disabled')
          document.getElementById('x').value = event.detail.x
          document.getElementById('y').value = event.detail.y
          document.getElementById('w').value = event.detail.width
          document.getElementById('h').value = event.detail.height
        }
      })
      _this.loading = false
    },
    crop () {
      const _this = this
      this.config.loading = true
      const formdata = new FormData(document.getElementById('cropForm'))
      formdata.append('postkey', this.auth.sessionID)
      formdata.append('SessionID', this.auth.sessionID)
      $('#cropperModal').modal('hide')
      this.axios.post(this.config.projectURL + 'p/crop', formdata)
        .then(function (response) {
          console.log(response)
          if (response.status === 250) {
            try {
              _this.user.profile_img = response.data
              _this.profile.profile_img = response.data
              _this.config.loading = false
            } catch (e) {
              console.log(e)
            }
          } else if (response.status === 299) {
            _this.auth.update().then(() => { $(this).submit() }).catch(window.wurstsalat.$router.push('/login'))
          }
        })
    },
    addFriend () {
      const _this = this
      const postData = new FormData()
      formdata.append('postkey', this.auth.sessionID)
      formdata.append('SessionID', this.auth.sessionID)
      postData.append('profileuser', this.profile.username)

      axios.post(this.projectURL + 'profile/friendRequest', postData)
        .then(function (response) {
          if (response.status === 250) {
            _this.friendCheck = { accept: 0, user_1: _this.user.id }
          } else if (xhr.status === 299) {
            _this.auth.update().then(_this.addFriend).catch(function () { _this.$router.push('/login') })
          }
        })
    },
    acceptFriend (id) {
      const _this = this
      const postData = new FormData()
      formdata.append('postkey', this.auth.sessionID)
      formdata.append('SessionID', this.auth.sessionID)
      postData.append('id', id)

      this.axios.post(this.config.projectURL + 'profile/friendRequestAccept', postData)
        .then(function (response) {
          if (response.status === 250) {
            _this.friendCheck = { accept: 1, user_1: _this.profile.id, id: response.data }
            if (_this.allFriends.length < 12) {
              _this.allFriends.push({
                account_public: 1,
                firstname: _this.user.firstname,
                friendshipid: id,
                id: _this.user.id,
                online: 1,
                profile_img: _this.user.profile_img,
                username: _this.user.username
              })
            }
          } else if (xhr.status === 299) {
            _this.auth.update().then(_this.acceptFriend).catch(function () { _this.$router.push('/login') })
          }
        })
    },
    declineFriend (id) {
      const _this = this
      const postData = new FormData()
      formdata.append('postkey', this.auth.sessionID)
      formdata.append('SessionID', this.auth.sessionID)
      postData.append('id', id)

      axios.post(this.projectURL + 'profile/friendRequestDelete', postData)
        .then(function (response) {
          if (response.status === 250) {
            _this.friendCheck = false
            for (const key in _this.allFriends) {
              if (_this.allFriends[key].id === _this.user.id)_this.allFriends.splice(key, 1)
            }
          } else if (xhr.status === 299) {
            _this.auth.update().then(_this.declineFriend).catch(function () { _this.$router.push('/login') })
          }
        })
    },
    follow () {
      const _this = this
      const postData = new FormData()
      postData.append('SessionID', sessionStorage.getItem('SessionID'))
      postData.append('postkey', sessionStorage.getItem('postkey'))
      postData.append('id', this.profile.id)
      const url = (this.followCheck) ? 'profile/defollow' : 'profile/follow'

      this.axios.post(this.projectURL + url, postData)
        .then(function (response) {
          console.log(response)
          if (response.status === 250) {
            _this.followCheck = !_this.followCheck
          } else if (xhr.status === 299) {
            _this.auth.update().then(_this.follow).catch(function () { _this.$router.push('/login') })
          }
        })
    }
  }
}
</script>
<style>
#UserProfile{
  padding-bottom: 80px;
}
#ProfileNav{
  padding: 16px;
  padding-bottom: 0;
}
#ProfileNav i{
  font-size: 1.2em;
}
#ProfileImage img{
  border-radius: 90%;
  max-width: 300px;
  display: block;
  margin: 0 auto;
  border: 8px solid #ffffff
}
#ProfileTitle{
  text-align: center;
  margin-top: 32px;
  background-color: #ffffff;
  border-radius: 16px;
  padding-top: 16px;
}
#ProfileDescription{
  background-color: #1DBEFB;
  color: rgba(255,255,255,0.6);
  border-radius: 16px;
  padding: 16px;
}
#ProfileInterests{
  margin-top: 32px;
  text-align: center;
}
#ProfileInterests .ui.basic.label{
  border-radius: 16px;
}
#ProfileInterests .ui.basic.label img{
  display: inline;
}
#ProfileCTAs .col-sp-4{
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
}
#ProfileCTAs .col-sp-4:hover img{
  transition: 0.3s;
  background-color: #009ad6;
}
#ProfileCTAs .col-sp-4 img{
  background-color: #ffffff;
  padding: 8px;
  border-radius: 90%;
  max-width: 60px;
  border: #009ad6 4px solid;
}
#ProfileCTAs .col-sp-4 span{
  font-size: 0.9em;
}
#InfoTab, #FragebogenTab, #GalerieTab{
  background-color: #ffffff;
  border-radius: 16px;
  padding: 16px;
}
.profile-image-edit-cta button.circular.inverted.ui.basic.mini.button.icon{
  color: #009ad6!important;
  border: solid 2px #009ad6!important;
  font-size: 0.9em;
  font-size: 0.9em;
}
</style>


