import { defineStore } from 'pinia'
import {socketStore} from "@/store/socketStore";
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {reactive} from "vue";

export const wallStore = defineStore('wall', {
  state: () => ({
    posts: {}, //main posts objectArray,
    singlePost: {}, //single post
    singlePostID: -1, //singe post ID
    lastPostID: -1, //highest post ID
    oldestPostID: 99999999, //lowest post ID
    lazyLoading: true, //value for lazyLoading, false if end of posts
    initLoading: true, //loader while loading first posts
    likeImg: ['thumbs-up.png', 'smiling-face-with-hearts.png', 'sad-but-relieved-face.png', 'rolling-on-the-floor-laughing.png', 'rainbow-flag.png', 'hugging-face.png', 'angry-face.png', 'sweet.png', 'flame.png'], //liketype images
  }),
  getters: {
  },
  actions: {
    /**
     * register Listener for Socket.io
     */
    registerListener() {
      const _this = this
      const socket = socketStore()
      const auth = authStore()

      /**
       * load multiple posts at beginning
       */
      socket.socket.on('wallPosts', (data) => {
        try {
          let i = true
          for (const key in data) {
            if(i) _this.lastPostID = data[key].id
            i = false
            if (!Object.prototype.hasOwnProperty.call(this.posts, data[key].id)) {
              _this.posts[data[key].id] = data[key]
              _this.posts[data[key].id].comments = {}
              _this.loadComments(data[key].id)
            }
            _this.oldestPostID = data[key].id
          }
          _this.lazyLoading = (data.length < 10)
        } catch (e) {
          console.error(e)
        }
      })

      /**
       * ask for new post
       */
      socket.socket.on('newPost', (data) => {
        if (!socket.smids.includes(data.smid)) {
          socket.smids.push(data.smid)
          socket.socket.emit('loadSinglePost', { type: 'new', lastPostID: data.id, room: auth.nodeID })
        }
      })

      /**
       * load new post
       */
      socket.socket.on('newWallPosts', (data) => {
        const tempPosts = _this.posts
        _this.posts = {}
        try {
          let i = true
          for (const key in data) {
            if(i) _this.lastPostID = data[key].id
            i = false
            if (!Object.prototype.hasOwnProperty.call(tempPosts, data[key].id)) {
              _this.posts[data[key].id] = data[key]
              _this.posts[data[key].id].comments = {}
              _this.loadComments(data[key].id)
            }
          }
          for (const key in tempPosts) {
            _this.posts[key] = tempPosts[key]
          }
        } catch (e) {
          console.error(e)
        }
      })

      /**
       * load single post
       */
      socket.socket.on('singlePost', (data) => {
        try {
          for (const key in data) {
            _this.singlePost[data[key].id] = data[key]
            _this.singlePost[data[key].id].comments = {}
            _this.loadComments(data[key].id)
          }
        } catch (e) {
          console.error(e)
        }
      })

      /**
       * ask for new comment
       */
      socket.socket.on('newComment', (data) => {
        if (!socket.smids.includes(data.smid)) {
          socket.smids.push(data.smid)
          if (Object.prototype.hasOwnProperty.call(_this.posts, data.postID)) {
            socket.socket.emit('loadSingleComment', { postID: data.postID, commentID: data.commentID, room: auth.nodeID })
          }
        }
      })

      /**
       * load comment for post
       */
      socket.socket.on('wallComments', (data) => {
        if (Object.prototype.hasOwnProperty.call(_this.posts, data.postID)) {
          for (const key in data.comments) {
            if (!Object.prototype.hasOwnProperty.call(_this.posts[data.postID].comments, data.comments[key].id) && data.comments[key].id != null) {
              _this.posts[data.postID].comments[data.comments[key].id] = data.comments[key]
            }
          }
        }
      })

      /**
       * remove post from array
       */
      socket.socket.on('deletedPost', (data) => {
        try {
          if (Object.prototype.hasOwnProperty.call(_this.posts, data.postID)) {
            delete (_this.posts[data.postID])
          }
        } catch (e) {
          console.error(e)
        }
      })

      /**
       * mark comment as deleted
       */
      socket.socket.on('deletedComment', (data) => {
        if (Object.prototype.hasOwnProperty.call(_this.posts, data.postID) && Object.prototype.hasOwnProperty.call(_this.posts[data.postID].comments, data.commentID)) _this.posts[data.postID].comments[data.commentID].deleted = 1
      })

      /**
       * add typing users to post
       */
      socket.socket.on('typingPost', (data) => {
        if (data.userID !== user.user_id && Object.prototype.hasOwnProperty.call(_this.posts, data.postID)) {
          _this.posts[data.postID].typingUser[data.userID] = {id: data.userID, img: data.userProfileImg, time: Date.now()}
        }
      })

      /**
       * add new like to post
       */
      socket.socket.on('newPostLike', (data) => {
        if (!socket.smids.includes(data.smid)) {
          socket.smids.push(data.smid)
          if (Object.prototype.hasOwnProperty.call(_this.posts, data.id)) {
            _this.posts[data.id].likes = []
            _this.posts[data.id].likeTypes = data.like_types.split(',')
            _this.posts[data.id].likeCounts = data.like_counts.split(',')
          }
        }
      })

      /**
       * add new like to comment
       */
      socket.socket.on('newCommentLike', (data) => {
        if (!socket.smids.includes(data.smid)) {
          socket.smids.push(data.smid)
          if (Object.prototype.hasOwnProperty.call(_this.posts, data.post_id) && Object.prototype.hasOwnProperty.call(_this.posts[data.post_id].comments, data.id)) {
            _this.posts[data.post_id].comments[data.id].likes = []
            _this.posts[data.post_id].comments[data.id].likeTypes = data.like_types.split(',')
            _this.posts[data.post_id].comments[data.id].likeCounts = data.like_counts.split(',')
          }
        }
      })

      /**
       * on (re)connect ask for missed posts or for the last ones
       */
      _this.lastPostID > 0 ? socket.socket.emit('updateWall', { room: auth.nodeID, lastPostID: _this.lastPostID }) : socket.socket.emit('loadPosts', { room: auth.nodeID, lastPostID: _this.lastPostID })
    },
    /**
     * load single post
     * @param id {string}
     */
    loadSinglePost(id) {
      const _this = this
      const socket = socketStore()
      const auth = authStore()
      socket.socket.emit('loadPosts', { type: 'single', room: auth.nodeID, lastPostID: _this.singlePostID })
    },
    /**
     * load 10 more posts
     */
    loadMore(){
      if(this.lazyLoading || this.type == 'single') return
      this.lazyLoading = true
      const _this = this
      const socket = socketStore()
      const auth = authStore()
      socket.socket.emit('loadPosts', { room: auth.nodeID, type: 'wall', lastPostID: _this.oldestPostID })
    },
    /**
     * load comments for post
     * @param id {string}
     */
    loadComments(id){
      const socket = socketStore()
      const auth = authStore()
      socket.socket.emit('loadComments', { room: auth.nodeID, postID: id })
    }
  },
})
