<template>
  <div
    v-if="size =='small'"
    class="content"
  >
    <a
      v-if="notidata.status == 1"
      class="author"
    >Deine Verifizierung war erfolgreich!</a>
    <a
      v-else
      class="author"
    >Deine Verifizierung ist fehlgeschlagen.</a>
    <div
      v-if="notidata.status == 1"
      class="text"
    >
      Du bist nun als überprüft gekennzeichnet.
      <img src="assets/images/fake-check.png">
    </div>
    <div
      v-else
      class="text"
    >
      Leider konnten wir dich nicht verifizieren.
      <img src="assets/images/match-dislike.png">
    </div>
    <div
      class="metadata t-right"
      style="display: block"
    >
      <div class="date">
        {{ date }}
      </div>
    </div>
  </div>
  <div
    v-else
    class="col-sp-9 col-sd-11 row-sp-12 pad-16"
  >
    <h4
      v-if="notidata.status == 1"
      class="ui header"
    >
      Deine Verifizierung war erfolgreich!
      <div class="sub header">
        Du bist nun als überprüft gekennzeichnet.
        <img src="assets/images/fake-check.png">
      </div>
    </h4>
    <h4
      v-else
      class="ui header"
    >
      Deine Verifizierung ist fehlgeschlagen.
      <div class="sub header">
        Leider konnten wir dich nicht verifizieren.
        <img src="assets/images/match-dislike.png">
      </div>
    </h4>
    <div class="t-right">
      {{ date }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Verified',
  props: ['notidata', 'timestamp', 'username', 'size'],
  data () {
    return {
      now: Date.now(),
      time: this.getTime()
    }
  },
  computed: {
    date () {
      return this.$parent.calculateTime(this.time, this.now)
    }
  },
  created () {
    setInterval(this.getNow, 5000)
  },
  mounted () {
    $('#notis').dropdown()
  },
  methods: {
    getNow: function () {
      this.now = Date.now()
    },
    getTime () {
      const arr = this.timestamp.split(/[- :.T]/)
      return new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]).getTime()
    }
  }
}
</script>

<style scoped>
h4.ui.header .sub.header img{
  max-width: 20px;
}
</style>
