<template>
  <div
    id="chatbox- this.partnerId "
    class="contact-element"
    @click="$emit('open', partner.id)"
  >
    <div class="contact-profile-img">
      <img
        class="ui avatar image"
        :src="config.projectURL + partner.profile_img "
      >
      <a
        class="onlinestatus ui green empty circular label"
        style="display: none;"
      />
    </div>
    <div class="contact-content">
      <h6 class="ui header">
        partner.firstname
        <div class="sub header last_dm">
          lastMessageContent
        </div>
      </h6>
    </div>
    <div class="contact-extra">
      <p class="time">
        lastMessageDate
      </p>
      <p class="favorite">
        <i
          class="icon star"
          title="Favorit"
        />
        <i
          class="icon heart"
          title="Ihr habt ein Match"
        />
        <i
          class="icon circle"
          title="Neue Nachricht"
        />
      </p>
    </div>
  </div>
</template>

<script>
import {configStore} from "@/store/configStore";

export default {
  name: "Partner",
  props: ['partner'],
  setup() {
    const config = configStore()

    return {config}
  },
  computed: {
    lastMessageContent(){
       return this.partner.messages.at(-1).content.substring(0, 40)
    },
    lastMessageDate(){
      return this.partner.messages.at(-1).dateString
    }
  }
}
</script>

<style scoped>

</style>