<template>
  <div
    v-if="config.loading"
    id="FullSizeLoader"
    class="rainbowloader"
  >
    <div class="loader-inner">
      <div class="loader-line-wrap">
        <div class="loader-line" />
      </div>
      <div class="loader-line-wrap">
        <div class="loader-line" />
      </div>
      <div class="loader-line-wrap">
        <div class="loader-line" />
      </div>
      <div class="loader-line-wrap">
        <div class="loader-line" />
      </div>
      <div class="loader-line-wrap">
        <div class="loader-line" />
      </div>
    </div>
  </div>
  <report />
  <router-view name="navBar" />
  <router-view @loaded="config.loading = false" />
  <router-view name="footer" />
  <transition name="modal">
    <modal
      v-show="chat.visible"
      @close="chat.visible = false"
    >
      <div
        class="messenger-background"
        @click="chat.visible = false"
      >
        <messages />
      </div>
    </modal>
  </transition>
</template>

<script>
import Report from '@/components/basic/report'
import Messages from '@/views/messages'
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {authStore} from "@/store/authStore";
import {socketStore} from "@/store/socketStore";
import {wallStore} from "@/store/wallStore";
import {chatStore} from "@/store/chatStore";

export default {
  name: 'Wurstsalat',
  components: { Messages, Report },
  title: 'Wurstsalat',
  setup(){
    const config = configStore()
    const user = userStore()
    const auth = authStore()
    const socket = socketStore()
    const wall = wallStore()
    const chat = chatStore()

    config.$subscribe((mutation, state) => {
      if(state.darkMode){
        localStorage.setItem('darkMode', JSON.stringify(1))
        document.getElementsByTagName('html')[0].setAttribute('class', 'dark')
      }else{
        localStorage.setItem('darkMode', JSON.stringify(0))
        document.getElementsByTagName('html')[0].removeAttribute('class')
      }
    })
    window.projectURL = config.projectURL
    return {config, user, auth, socket, wall, chat}
  },
  data () {
    return {
    }
  },
  watch: {
    chatModal () {
      if (this.chatModal) {
        $('body').addClass('no-scroll')
      } else {
        $('body').removeClass('no-scroll')
      }
    }
  },
  mounted () {
    const _this = this
    this.socket.connect()
    this.auth.update()
    setInterval(()=>{
      _this.config.now = Date.now()
    },1000)
  },
  methods: {
    /**
     *
     * @param event
     */
    closeChat (event) {
      if (event.target.classList.contains('messenger-background')) {
        this.chatModal = false
        $('body').removeClass('no-scroll')
      }
    },
    /**
     *
     * @param event
     */
    closeCall (event) {
      if (event.target.classList.contains('messenger-background')) {
        this.callModal = false
        $('body').removeClass('no-scroll')
      }
    }
  }
}
</script>

<style lang="scss">
.ptr--icon,
.ptr--text {
 color: rgb(23 23 23 / 72%) !important;
}
body {
  height: 100vh !important;
}
.spaceTop {
  margin-top: calc(constant(safe-area-inset-top) + 54px) !important;
  margin-top: calc(env(safe-area-inset-top) + 54px) !important;
}
.sidebar {
  top: calc(constant(safe-area-inset-top) + 54px) !important;
  top: calc(env(safe-area-inset-top) + 54px) !important;
  z-index: 1001 !important;
}
.notchFix {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
.blurPage{
  /* Any browser which supports CSS3 */
  background: url('assets/images/auth.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 1000%;
  position: absolute;
  left: 0;
  top: 0;

  z-index: 99999;
  overflow:hidden;
}
.no-scroll{
  overflow: hidden;
}
.modal-mask {
  position: fixed;
  z-index: 1111;
  opacity: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  transition: all 0.3s ease;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.messenger-background {
  backdrop-filter: brightness(20%) blur(5px);
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
}
.messenger-foreground {
  @media (min-width: 992px){
    max-width: 80% !important;
    max-height: 80% !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%,-50%) !important;
    /* border-radius: 5px; */
  }
  overflow: hidden;
}
.vueModal{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(20px) brightness(0.5);
}
.vueModalInner{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 20px;
  border-radius: 10px;
  background-color: white;
}
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
.sidebar-leave-active,
.sidebar-enter-active{
  transition: all 0.3s ease;
}

.sidebar-enter-from,
.sidebar-leave-to{
  left: -300px;
  opacity: 0;
}
// @import "assets/scss/main.scss";
</style>
