<template>
  <div v-if="type == 'single'">
    <post :post="wall.single" />
  </div>
  <div v-else>
    <post
      v-for="post in wall.posts"
      :key="post.id"
      :post="post"
    />
  </div>
</template>

<script>
import post from "@/components/wall/post";
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {wallStore} from "@/store/wallStore";
import {configStore} from "@/store/configStore";
export default {
  name: "Wall",
  components: {post},
  props: ['type'],
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()
    const wall = wallStore()

    return {auth, config, user, wall}
  },
  data() {
    return {
      scrollToTop: false
    }
  },
  mounted() {
    const _this = this
    if(this.type === 'single') this.wall.loadSingle(this.$route.params.post)
    window.addEventListener('scroll', _this.scroll)
  },
  methods: {
    scroll(){
      const scrollTemp = $(document).scrollTop()
      if ((scrollTemp + 400) >= ($(document).innerHeight() - $('body').height()) && this.type !== 'single') {
        this.wall.loadMore()
      }
      this.scrollToTop = scrollTemp > $('body').height()
    }
  }
}
</script>

<style scoped>

</style>