<template>
  <div id="hoverModal" class="ui segment no-shadow profile-preview">
    <h5 class="ui header active">
      <img id="hoverModalImg" class="ui avatar image">
      <div class="content">
        <span id="hoverModalFirstName" />
        <div id="hoverModalUserName" class="sub header active"/>
      </div>
    </h5>
  </div>
  <div class="ui mini deletePost modal">
    <i class="close icon" />
    <div class="header">
      Biste du dir sicher?
    </div>
    <div class="content">
      Dieser Post wird unwiderruflich gelöscht.
    </div>
    <div class="actions">
      <div class="ui tiny button" onclick="$('.deletePost.modal').modal('hide');">
        Abbrechen
      </div>
      <div class="ui tiny button approve btn-color-1">
        Löschen
      </div>
    </div>
  </div>
  <div class="ui mini deleteComment modal">
    <i class="close icon" />
    <div class="header">
      Biste du dir sicher?
    </div>
    <div class="content">
      Dieser Kommentar wird unwiderruflich gelöscht.
    </div>
    <div class="actions">
      <div
        class="ui tiny button"
        onclick="$('.deleteComment.modal').modal('hide');"
      >
        Abbrechen
      </div>
      <div class="ui tiny button approve btn-color-1">
        Löschen
      </div>
    </div>
  </div>
  <div id="page-dashboard" class="spaceTop">
    <div id="ptrest" />
    <div class="content-800">
        <div id="ptrPull">
            <div class=" pad-4">
              <h6 class="page-subtitle" style="margin-left: 18px;">
                Willkommen zurück {{ user.firstname }}
              </h6>
            </div>
          <section id="PostFunction" class="pad-16">
            <form @submit.prevent=post id="postIt" class="ui form">
              <input type="hidden" name="galery" value="0">
              <input type="hidden" name="wall" value="1">
              <div class="card">
                <div class="card-body p-0">
                  <div id="preview-col" class="pimg pad-8" style="display: none;">
                    <img id="output_image">
                  </div>
                  <textarea v-model=postText id="postText" style="border-radius: 0;" class="form-control border-0" rows="4" name="postcontent" placeholder="Was beschäftigt dich gerade?" required/>
                </div>
                <div class="card-footer py-2">
                  <div>
                    <div class="pad-8 t-right">
                      <div class="ui button basic icon smileyMenue display-Dektop-inline" data-menu="0">
                        <i class="smile outline icon" />
                      </div>
                      <button id="postButton" type="submit" class="ui button btn-color-1">
                        Posten
                      </button>
                    </div>
                  </div>
                </div>
                <div id="emojiCon_0" class="emojiCon">
                  <emojimenu />
                </div>
              </div>
            </form>
          </section>
        </div>
        <section id="wall" class="pad-16 ptab active postList">
          <div id="errorModal" class="ui mini modal">
            <div id="errorMessageMTi" class="header"/>
            <div id="errorMessageMT" class="content"/>
          </div>
          <div class="grid-16">
            <div class="col-sp-16 col-sd-10 row-sp-16 pad-4"></div>
            <div class="col-sp-16 col-sd-6 row-sp-16 t-right pad-4">
              <div id="maxDiB" class="ui floating large fluid btn-inverted-color dropdown labeled icon button" style="text-align: center!important;">
                <i class="map marker alternate icon" />
                <span class="text" />
                <input id="maxDiSel" type="hidden">
                <div class="menu">
                  <div class="item" data-value="10">
                    Mein Standort
                  </div>
                  <div class="item" data-value="20">
                    20km
                  </div>
                  <div class="item" data-value="50">
                    50km
                  </div>
                  <div class="item" data-value="100">
                    100km
                  </div>
                  <div class="item" data-value="200">
                    200km
                  </div>
                  <div class="item" data-value="500">
                    500km
                  </div>
                </div>
              </div>
            </div>
          </div>
          <wall type="wall" />
          <div class="pad-32">
            <div id="loader" class="ui centered inline loader"/>
          </div>
        </section>
    </div>
  </div>
</template>

<script>
import emojimenu from '@/components/plugins/emojimenu'
import tinyuser from '@/components/basic/tinyUser'
import { semantic} from '@/modules/main'
import PullToRefresh from 'pulltorefreshjs'
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {authStore} from "@/store/authStore";
import Wall from "@/components/wall/wall";

export default {
  name: 'Start',
  title: 'Wurstsalat | Wall',
  components: {
    Wall,
    emojimenu
  },
  setup() {
    const auth = authStore()
    const user = userStore()
    const config = configStore()

    return {auth, user, config}
  },
  data () {
    return {
      locationUser: [],
      newUser: [],
      lastOnlineUser: [],
      article: false,
      verified: 0,
      tab: 'near',
      postText: ''
    }
  },
  mounted () {
    const _this = this
    this.loadSpotlight()
    semantic()
    PullToRefresh.init({
      mainElement: document.querySelector('#ptrest'),
      triggerElement: document.querySelector('#ptrPull'),
      instructionsPullToRefresh: 'ziehen...',
      instructionsReleaseToRefresh: 'Loslassen...',
      instructionsRefreshing: 'aktualisiere...',
      iconArrow: '<i class="fas fa-angle-down fa-2x"></i>',
      shouldPullToRefresh () {
        return !_this.chat.visible
      },
      onRefresh () {
        window.location.reload()
      }
    })

    this.config.loading = false //tell the application to remove the loading div
  },
  methods: {
    post() {
      const _this = this
      const fD = new FormData()
      fD.append('SessionID', this.auth.sessionID)
      console.log(this.postText)
      fD.append('postcontent', this.postText)
      this.axios.post(this.config.projectURL + 'wall/newPost', fD)
          .then(function(response) {
            console.log(response.status, response.data)
            if(response.status === 250) _this.postText = ''
          }).catch(e => {console.log(e)})

    },
    loadSpotlight() {
      const _this = this
      const fD = new FormData()
      fD.append('SessionID', this.auth.sessionID)
      this.axios.post(window.projectURL + 'dashboard/getData', fD)
          .then(function (response) {
            if (response.status === 250) {
              const data = response.data
              try {
                data.newUser.forEach((value, index) => {
                  _this.newUser.push(value)
                })
              }catch (e) {}
              try {
                data.lastOnlineUser.forEach((value, index) => {
                  _this.lastOnlineUser.push(value)
                })
              }catch (e) {}
              try {
                data.locationUser.forEach((value, index) => {
                  _this.locationUser.push(value)
                })
              }catch (e) {}
            }
          })
          .catch(function (err) {
            console.error(err)
          })
    },
    openSearch () {
      this.$router.push({ name: 'Search', query: { t: 'User' } })
    },
    search (e) {
      this.$router.push({ name: 'Search', query: { s: e.target.value } })
    }

  }
}

</script>

<style>

</style>
{"21384":{"id":21384,"user_id":5231,"deleted":0,"content":"Ja genau","timestamp":"2022-01-22T21:27:54.000Z","firstname":"Sascha","username":"BestFiction","profile_img":"86dd8f8ec3648ad3655c4e710d554bea.jpg","last_active_timestamp":"2022-03-21T00:15:36.000Z","verified":0,"like_types":null,"like_counts":null,"ownlike":0},"21385":{"id":21385,"user_id":5231,"deleted":0,"content":"Test 2","timestamp":"2022-01-22T21:40:06.000Z","firstname":"Sascha","username":"BestFiction","profile_img":"86dd8f8ec3648ad3655c4e710d554bea.jpg","last_active_timestamp":"2022-03-21T00:15:36.000Z","verified":0,"like_types":null,"like_counts":null,"ownlike":0},"21385":{"id":28385,"user_id":5231,"deleted":0,"content":"Test 2","timestamp":"2022-01-22T21:40:06.000Z","firstname":"Sascha","username":"BestFiction","profile_img":"86dd8f8ec3648ad3655c4e710d554bea.jpg","last_active_timestamp":"2022-03-21T00:15:36.000Z","verified":0,"like_types":null,"like_counts":null,"ownlike":0}}