<template>
  <div id="page-login">
    <div id="page-login-header">
      <div class="header-content">
        <a href="/login">
          <img src="assets/images/dark-wurstsalat-beta-pride.png">
        </a>
      </div>
    </div>
    <div id="page-login-video">
      <video
        id="bgvideo"
        preload="auto"
        autoplay
        playsinline
        muted
        loop
      >
        <source
          id="bgvideosource"
          type="video/mp4"
        >
      </video>
    </div>
    <div id="page-login-footer">
      <div class="cta-content">
        <button
          class="ui button circular large fluid btn-color-1"
          @click="tab='login'"
        >
          Einloggen
        </button>
        <div class="ui divider inverted horizontal">
          oder
        </div>
        <button
          class="ui button circular large fluid btn-color-2"
          @click="tab='register'"
        >
          kostenlos anmelden
        </button>
        <ul>
          <li>
            <router-link to="/impressum">
              Impressum
            </router-link>
          </li>
          <li>
            <router-link to="/datenschutz">
              Datenschutz
            </router-link>
          </li>
          <li>
            <router-link to="/nutzungsbedingungen">
              Nutzungsbedingungen
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Modals -->
  <transition-group name="modal">
    <div
      v-if="tab === 'login'"
      id="LoginModal"
      class="vueModal"
    >
      <div class="content vueModalInner">
        <div
          v-if="wrongPassword"
          id="loginFail"
          class="ui negative message"
        >
          <i class="close icon" />
          <div class="header">
            Ups!
          </div>
          <p>Email oder Passwort falsch</p>
        </div>
        <form
          id="loginForm"
          class="ui form"
          @submit.prevent="login"
        >
          <h2 class="ui header">
            <img src="assets/images/emoticons/hi.png">
            <div class="content">
              Hey
              <div class="sub header">
                Bitte logge dich ein
              </div>
            </div>
          </h2>
          <div class="field">
            <label>E-Mail oder Benutzername</label>
            <input
              v-model="email"
              type="text"
              name="email"
              placeholder="E-Mail oder Benutzername"
              required
              autofocus
              autocomplete="username"
            >
          </div>
          <div class="field">
            <label style="float: left;">Passwort</label>
            <input
              v-model="password"
              type="password"
              name="loginpassword"
              placeholder="Passwort"
              required
              autocomplete="current-password"
            >
          </div>
          <div class="t-right">
            <div class="pad-4" />
            <a
              class="password-forget"
              @clikc="tab = 'resetPassword'"
            >Passwort vergessen?</a>
          </div>
          <div class="field">
            <input
              v-model="stayIn"
              type="checkbox"
              tabindex="0"
              class=""
              name="stayIn"
            >
            <label>Angemeldet bleiben</label>
          </div>
          <div class="pad-8" />
          <button
            type="submit"
            class="ui button circular large fluid btn-color-1"
          >
            Einloggen
          </button>
          <div class="pad-4" />
          <a
            class="ui button circular large fluid btn-inverted-color"
            style="border: 1px solid rgba(34, 36, 38, 0.15);"
            @click="tab = ''"
          >
            Abbrechen
          </a>
        </form>
      </div>
    </div>
    <div
      v-if="tab === 'resetPassword'"
      id="PasswortForgetModal"
      class="vueModal"
    >
      <div class="content vueModalInner">
        <div
          v-if="pwReset == 'successs'"
          class="ui success message"
        >
          <i class="close icon" />
          <div class="header">
            Passwort zurückgesetzt
          </div>
          <p>Bitte überprüfe dein E-Mail Postfach um fortzufahren.</p>
        </div>
        <div
          v-if="pwReset == 'fail'"
          id="passwordForgetFail"
          class="ui negative message"
        >
          <i class="close icon" />
          <div class="header">
            Ups!
          </div>
          <p>Diese E-Mail ist uns nicht bekannt.</p>
        </div>
        <form
          id="passwordResetForm"
          class="ui form"
          @submit.prevent="resetPassword"
        >
          <input
            type="hidden"
            name="type"
            value="password"
            required
          >
          <h2 class="ui header t-center">
            <div class="content">
              Passwort zurücksetzen
              <div class="sub header">
                Bitte trage deine E-Mail Adresse ein
              </div>
            </div>
          </h2>
          <div class="field">
            <label>E-Mail</label>
            <input
              v-model="email"
              type="email"
              name="email"
              placeholder="E-Mail"
              required
              autofocus
            >
          </div>
          <div class="pad-8" />
          <button
            type="submit"
            class="ui button circular large fluid btn-color-1"
          >
            Passwort zurücksetzen
          </button>
          <div class="pad-4" />
          <a
            class="ui button circular large fluid btn-inverted-color"
            style="border: 1px solid rgba(34, 36, 38, 0.15);"
            @click="tab == ''"
          >
            Abbrechen
          </a>
        </form>
      </div>
    </div>
    <div
      v-if="tab === 'restorePasswort'"
      id="PasswortResetModal"
      class="vueModal"
    >
      <div class="content vueModalInner">
        <div
          v-if="pwRestore == 'password'"
          class="ui negative message"
        >
          <i class="close icon" />
          <div class="header">
            Ups!
          </div>
          <p>Ein Fehler ist aufgetreten.</p>
        </div>
        <div
          v-if="pwRestore == 'key'"
          class="ui negative message"
        >
          <i class="close icon" />
          <div class="header">
            Ups!
          </div>
          <p>Der Code stimmt nicht.</p>
        </div>
        <form
          id="restorePasswordForm"
          class="ui form"
          @submit.prevent="restorePassword"
        >
          <h2 class="ui header">
            <div class="content">
              Passwort zurücksetzen
              <div class="sub header">
                Bitte lege dein Passwort fest
              </div>
            </div>
          </h2>
          <input
            type="hidden"
            name="type"
            value="login"
            required
          >
          <div class="field">
            <label>Passwort</label>
            <input
              id="inputPassword"
              v-model="password1"
              type="password"
              name="password"
              placeholder="Passwort"
              required
              minlength="8"
              autocomplete="new-password"
            >
          </div>
          <div class="field">
            <label>Passwort wiederholen</label>
            <input
              id="inputPassword2"
              v-model="password2"
              type="password"
              name="passwordwdh"
              placeholder="Passwort wiederholen"
              required
              minlength="8"
              autocomplete="new-password"
            >
          </div>
          <div class="pad-8" />
          <button
            type="submit"
            class="ui button circular large fluid btn-color-1"
          >
            Passwort zurücksetzen
          </button>
          <div class="pad-4" />
          <a
            class="ui button circular large fluid btn-inverted-color"
            style="border: 1px solid rgba(34, 36, 38, 0.15);"
            @click="tab == ''"
          >
            Abbrechen
          </a>
        </form>
      </div>
    </div>
    <div
      v-if="tab === 'register'"
      id="RegisterModal"
      class="vueModal"
    >
      <div class="content vueModalInner">
        <div
          v-if="regError == 'email'"
          class="ui negative message"
        >
          <i class="close icon" />
          <div class="header">
            Diese E-Mail oder Username sind vergeben oder ungültig.
          </div>
          <p>Diese Email-Adresse ist ungültig oder es besteht bereits ein Account mit dieser E-Mail-Adresse oder Usernamen.</p>
        </div>
        <div
          v-if="regError == 'bot'"
          class="ui negative message"
        >
          <i class="close icon" />
          <div class="header">
            Ups! Bist du ein Bot? &#129302;
          </div>
          <p style="overflow-wrap: break-word;">
            Falls ja: 01000100011001010100100101001110011001010010000001001101011101010100010001100100011000010010000001001001010100110111010000100000011001010110100101001110001000000101010001101111011000010101001101110100010001010101001000100001
          </p>
          <p> Falls nein: versuche es später noch einmal oder <a href="mailto:Support@wurstsalat.app">kontaktiere den Support.</a> &#128535;</p>
        </div>
        <div
          v-if="regError == 'fail'"
          class="ui negative message"
        >
          <i class="close icon" />
          <div class="header">
            Ups! Da ging was schief!
          </div>
          <p>Überprüfe deine eingaben und versuche es erneut. Falls du weiterhin Probleme haben solltest, zögere nicht den <a href="mailto:Support@wurstsalat.app">Support zu kontaktieren.</a></p>
        </div>
        <div
          v-if="regError == 'age'"
          class="ui negative message"
        >
          <i class="close icon" />
          <div class="header">
            Leider ist die Anmeldung nicht möglich
          </div>
          <p>Du bist leider außerhalb des Zielgruppen alters (14-29 Jahre).</p>
        </div>
        <div
          v-if="password1.length < 6 && password1.length > 0"
          id="toShort"
          class="ui negative message"
        >
          <div class="header">
            Dein Passwort ist leider zu kurz.
            &#x1F346;
          </div>
        </div>
        <div
          v-if="password1.length > 5 && password1 != password2"
          id="noMatch"
          class="ui negative message"
        >
          <i class="close icon" />
          <div class="header">
            Ups! Bitte überprüfe deine Eingaben
          </div>
          <p>Deine Passwörter sind nicht identisch oder zu kurz.</p>
        </div>
        <form
          id="regform"
          class="ui form"
          @submit.prevent="register"
        >
          <h2 class="ui header">
            <div class="content">
              Registration
              <div class="sub header">
                Bitte fülle die folgenden Felder aus
              </div>
            </div>
          </h2>
          <div class="field">
            <label>Vorname</label>
            <input
              v-model="firstname"
              type="text"
              name="firstname"
              placeholder="Vorname"
              pattern="[a-zA-Z0-9\szÄäÖöÜüß\-]+"
              required
            >
          </div>
          <div class="field">
            <label>E-Mail</label>
            <input
              v-model="email"
              type="email"
              name="email"
              placeholder="E-Mail"
              required
            >
          </div>
          <div class="field">
            <label>Benutzername</label>
            <input
              v-model="username"
              type="text"
              name="username"
              placeholder="Username"
              pattern="[\w]+"
              required
            >
          </div>
          <div class="field">
            <label>Passwort (mindestens 8 Zeichen)</label>
            <input
              id="pw1"
              v-model="password1"
              type="password"
              minlength="8"
              placeholder="Passwort"
              name="registerpassword"
              required
            >
          </div>
          <div class="field">
            <label>Passwort wiederholen</label>
            <input
              id="pw2"
              v-model="password2"
              type="password"
              minlength="8"
              placeholder="Passwort"
              name="registerpasswordwdh"
              required
            >
          </div>
          <div class="field">
            <div class="ui right labeled left icon input">
              <i class="birthday icon" />
              <input
                v-model="birth"
                type="date"
                placeholder="Geburtstag"
                name="dob"
                required
              >
              <a class="ui tag label">
                Geburtstag
              </a>
            </div>
            <div class="ui mini label">
              Wurstsalat ist exklusiv für Jungs* zwischen 14 und 29 Jahre
            </div>
          </div>
          <div class="field">
            <input
              v-model="agb"
              type="checkbox"
              tabindex="0"
              class=""
              required
            >
            <label>Ich habe die <a
              href="datenschutz"
              target="_blank"
            >Datenschutzerklärung</a>, sowie die <a
              href="nutzungsbedingungen"
              target="_blank"
            >Nutzungsbedingungen</a> gelesen und akzeptiere diese.</label>
          </div>
          <div class="pad-8" />
          <!--<button id="fsubmit" data-sitekey="6LfgwzsaAAAAAD5LM_6_fcdvibu3tNdNWJqQj3eP" data-callback='onSubmit' data-action='submit' class="g-recaptcha ui button circular large fluid btn-color-1" disabled>
            Registrieren
          </button>-->
          <button
            type="submit"
            class="g-recaptcha ui button circular large fluid btn-color-1"
          >
            Registrieren
          </button>
          <div class="pad-4" />
          <button
            class="ui button circular large fluid btn-inverted-color"
            style="border: 1px solid rgba(34, 36, 38, 0.15);"
            @click="tab == ''"
          >
            Abbrechen
          </button>
        </form>
      </div>
    </div>
  </transition-group>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: 'Login',
  title: 'Wurstsalat | Login',
  props: ['subpage'],
  setup() {
    const auth = authStore()
    const user = userStore()
    const config = configStore()

    return { auth, user, config }
  },
  data () {
    return {
      open: false,
      loader: false,
      tab: this.subpage,
      email: '',
      password: '',
      stayIn: false,
      password1: '',
      password2: '',
      wrongPassword: false,
      pwReset: '',
      pwRestore: '',
      regError: '',
      username: '',
      firstname: '',
      birth: '',
      agb: false,
    }
  },
  mounted () {
    const videoItems = ['assets/videos/login-background-video-1.mp4', 'assets/videos/login-background-video-5.mp4']
    $('#bgvideosource').attr('src', videoItems[Math.floor(Math.random() * videoItems.length)])

    if (this.$route.query.page === 'passwordforget') {
      this.tab = 'resetPassword'
    } else if (this.$route.query.page === 'resetpassword') {
      this.tab = 'restorePassword'
    } else if (this.$route.query.page === 'register') {
      this.tab = 'register'
    }

    function init () {
    /*
      regform.submit(function (e) {
        e.preventDefault()
        loader.show()
        // content.hide()

        const regData = new FormData(this)
        regData.append('type', 'passwordRestore')
        regData.append('resetkey', findGetParameter('resetkey'))
        regData.append('affiliate', findGetParameter('affiliate'))

        $.ajax({
          type: 'POST',
          url: window.projectURL + 'register/doRegister',
          data: regData,
          success: function (data, textStatus, xhr) {
            noMatch.hide()
            toShort.hide()
            regFail.hide()
            regBot.hide()
            regAge.hide()
            regEmail.hide()

            if (xhr.status === 250) {
              localStorage.setItem('currentPage', 'step1')
              if (process.env.CORDOVA_PLATFORM === undefined) {
                if (device.platform === 'iOS') {
                  try {
                    FirebasePlugin.grantPermission(hasPermission => {
                      console.log('Permission was ' + (hasPermission ? 'granted' : 'denied'))
                    })

                    firebaseSetup()
                  } catch (e) {
                    console.error('FirebasePlugin grantPermission error', e)
                  }
                } else {
                  firebaseSetup()
                }
              } else {
                pushWebToken()
              }
              router.push('/advancedregister/1')
            } else if (xhr.status === 251) {
              regBot.show()
              loader.hide()
              content.show()
            } else if (xhr.status === 252) {
              regEmail.show()
              loader.hide()
              content.show()
            } else if (xhr.status === 253) {
              regAge.show()
              loader.hide()
              content.show()
            } else if (xhr.status === 254) {
              noMatch.show()
              loader.hide()
              content.show()
            } else if (xhr.status === 255) {
              regFail.show()
              loader.hide()
              content.show()
            }
          },
          cache: false,
          contentType: false,
          processData: false
        })
      })

      $('#pw1,#pw2').on('input', function () {
        if (pw1.val() === 'penis' || pw1.val() === 'schwanz' || pw1.val() === 'cock' || pw1.val() === 'pimmel') {
          toShort.show()
        } else {
          toShort.hide()
        }
        if (pw2.val().length < 8) {
          fsubmit.prop('disabled', true)
        } else if (pw1.val() !== pw2.val()) {
          noMatch.show()
          fsubmit.prop('disabled', true)
        } else {
          noMatch.hide()
          fsubmit.prop('disabled', false)
        }
      })*/
    }


    this.config.loading = false
  },
  methods: {
    async login(){
      const _this = this
      this.loader = true

      this.auth.login(this.email, this.password)
        .then((resolve) => {
          if (resolve === 1) {
            _this.$router.push('advancedregister')
          } else if (_this.$route.query.redirect === undefined || _this.$route.query.redirect == null || _this.$route.query.redirect === '') {
            _this.$router.push('wall')
          } else {
            _this.$router.push(_this.$route.query.redirect)
          }
        })
        .catch((e) => {
            this.wrongPassword = true
            this.loader = false
        })
    },
    resetPassword(){
      const _this = this
      this.loader = true

      this.auth.resetPassword(this.email)
          .then((response) => {
            if (response === 250) {
              _this.pwReset = 'success'
            } else if (response === 251) {
              _this.pwReset = 'fail'
            }
            _this.loader = false
          })
    },
    restorePassword(){
      const _this = this
      this.loader = true

      this.auth.restorePassword(this.$route.query.resetkey)
          .then((response) => {
            if (response === 250) {
              _this.pwReset = ''
              _this.pwRestore = 'success'
              _this.wrongPassword = false
            } else if (response === 251) {
              _this.pwRestore = 'password'
            } else if (response === 252) {
              _this.pwRestore = 'key'
            }
          })
    },
    register(){
      const _this = this
      this.loader = true

      this.auth.register(email, username, firstname, birth)
          .then((response) => {
            if (response === 250) {
              _this.pwReset = ''
              _this.pwRestore = 'success'
              _this.wrongPassword = false
            } else if (response === 251) {
              _this.pwRestore = 'password'
            } else if (response === 252) {
              _this.pwRestore = 'key'
            }
            loader.hide()
            content.show()
          })

    }
  }
}

</script>

<style >
#page-login{
  background-image: url('/assets/images/new-login-background.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  min-height: 100vh;
  min-width: 100vw;
  margin: 0;
  padding: 0;
  position: relative;
}
#page-login-header{
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  min-height: 300px;
  background: rgb(57,0,85);
  background: linear-gradient(180deg, rgba(57,0,85,1) 3%, rgba(255,255,255,0) 100%);
}
#page-login-header .header-content{
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 350px;
  width: 100%;
  text-align: center;
}
#page-login-header .header-content img{
  max-width: 250px;
  width: 100%;
}
#page-login-video{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
#page-login-video #bgvideo{
  display: block;
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
#page-login-footer{
  position: fixed;
  z-index: 9;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  min-height: 300px;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 3%, rgba(255,255,255,0) 100%);
}
#page-login-footer .cta-content{
  position: absolute;
  bottom: 48px;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 350px;
  width: 100%;
  text-align: center;
}
#page-login-footer .cta-content ul{
  text-align: center;
  margin: 0;
  padding: 0;
  margin-top: 16px;
}
#page-login-footer .cta-content ul li{
  text-align: center;
  display: inline-block;
  margin: 0 4px;
  list-style: none;
}
#page-login-footer .cta-content ul li a{
  color: rgba(255,255,255,0.8);
  text-decoration: none;
  font-size: 0.8em;
}
#LoginModal .password-forget{cursor: pointer;}
#PasswortForgetModal .rainbowloader,
#PasswortResetModal .rainbowloader,
#LoginModal .rainbowloader{
  display: none;
  min-height: 300px;
}
</style>

/*
loginForm.submit(function (e) {
e.preventDefault()
loader.show()
// content.hide()

const loginData = new FormData(this)
loginData.append('type', 'login')

axios.post(url, loginData)
.then(function (response) {
console.log(response)
if (response.status === 250) {
$('.modal').modal('hide')
const pData = response.data
window.wurstsalat.user = pData

localStorage.setItem('account_public', pData.account_public)
localStorage.setItem('account_type', pData.account_type)
localStorage.setItem('email', pData.email)
localStorage.setItem('firstname', pData.firstname)
localStorage.setItem('age', pData.age)
localStorage.setItem('darkmode', pData.darkmode)
localStorage.setItem('ghostmode', pData.ghostmode)
localStorage.setItem('volume', pData.volume)
localStorage.setItem('lat', pData.lat)
localStorage.setItem('long', pData.long)
localStorage.setItem('premium_state', pData.premium_state)
localStorage.setItem('profile_img', pData.profile_img)
localStorage.setItem('register_status', pData.register_status)
localStorage.setItem('role', pData.role)
localStorage.setItem('subscribed_channels', pData.subscribed_channels)
localStorage.setItem('user_id', pData.user_id)
localStorage.setItem('username', pData.username)
localStorage.setItem('uname', pData.username)
localStorage.setItem('NodeID', pData.NodeID)
localStorage.setItem('SessionID', pData.SessionID)
localStorage.setItem('postkey', pData.postkey)
sessionStorage.setItem('postkey', pData.postkey)
sessionStorage.setItem('NodeID', pData.NodeID)
sessionStorage.setItem('SessionID', pData.SessionID)
if (process.env.CORDOVA_PLATFORM !== undefined && $('#keepIn').checkbox('is checked')) {
if (process.env.CORDOVA_PLATFORM === 'ios') {
try {
FirebasePlugin.grantPermission(hasPermission => {
console.log('Permission was ' + (hasPermission ? 'granted' : 'denied'))
})

firebaseSetup()
} catch (e) {
console.error('FirebasePlugin grantPermission error', e)
}
} else {
firebaseSetup()
}
} else if ($('#keepIn').checkbox('is checked')) {
pushWebToken()
}

sessionStorage.setItem('auth', 1)
if ($('#keepIn').checkbox('is checked')) {
localStorage.setItem('KeepID', pData.KeepID)
}
setWurstsalat()
if (localStorage.getItem('darkmode') === '1') {
document.getElementsByTagName('html')[0].setAttribute('class', 'dark')
if (process.env.CORDOVA_PLATFORM !== undefined) StatusBar.styleLightContent()
window.wurstsalat.darkMode = true
} else {
document.getElementsByTagName('html')[0].removeAttribute('class')
if (process.env.CORDOVA_PLATFORM !== undefined) StatusBar.styleDefault()
window.wurstsalat.darkMode = false
}

$('#LoginModal').modal('hide')
if (pData.account_type === 'business') {
location.href = 'businessdashboard'
} else if (pData.register_status === '0') {
router.push('advancedregister')
} else if (_this.$route.query.redirect === undefined || _this.$route.query.redirect == null || _this.$route.query.redirect === '') {
router.push('wall')
} else {
router.push(_this.$route.query.redirect)
}
} else if (response.status === 251) {
loginFail.show()
loader.hide()
content.show()
} else {
loader.hide()
content.show()
}
})
.catch(function () {
loader.hide()
content.show()
})
})
*/