<template>
  <nav id="MainMenu">
    <div class="content-1400 grid-16 ">
      <div class="col-sp-10 col-sd-3 row-sp-16">
        <router-link to="/home">
          <img
            id="MainMenuLogo"
            class="responsive-img"
            :src="logo"
          >
        </router-link>
      </div>
      <div class="col-sp-6 col-sd-9 row-sp-16">
        <ul>
          <li>
            <router-link to="/home">
              Home
            </router-link>
          </li>
          <li>
            <a href="magazin">Magazin</a>
          </li>
          <li>
            <router-link to="/about">
              Über Wurstsalat
            </router-link>
          </li>
          <li>
            <router-link to="/features">
              Features
            </router-link>
          </li>
          <li>
            <a
              href="https://www.wurstsalat.shop"
              target="_blank"
            >Shop</a>
          </li>
          <li
            id="MobileMenu"
            class=""
            @click="config.sidebar = !config.sidebar"
          >
            <a>
              <i class="bars icon" />
            </a>
          </li>
        </ul>
      </div>
      <div
        id="MainMenuCTA"
        class="col-sp-16 col-sd-4 row-sp-16 t-right pad-16"
      >
        <div v-if="auth.isAuth">
          <router-link
            to="/wall"
            class="ui primary button btn-color-1"
          >
            <img
              class="ui avatar image"
              :src="config.projectURL + 'assets/images/user/' + user.profile_img"
            >
            Community <i class="arrow right icon" />
          </router-link>
        </div>
        <div v-else>
          <router-link
            to="/login"
            class="ui primary button btn-color-1"
          >
            Login
          </router-link>
          <router-link
            to="/login/register"
            class="ui primary button btn-color-2"
          >
            Registrieren
          </router-link>
        </div>
        <!--
        <div class="ui small selection dropdown" style="display: inline;">
            <input type="hidden" name="country">
            <i class="dropdown icon"></i>
            <div class="default text"><i class="de flag"></i></div>
            <div class="menu">
                <div class="item" data-value="de"><i class="de flag"></i></div>
                <div class="item" data-value="at"><i class="at flag"></i></div>
                <div class="item" data-value="ch"><i class="ch flag"></i></div>
                <div class="item" data-value="gb"><i class="gb flag"></i></div>
            </div>
        </div>
        -->
      </div>
    </div>
    <!-- Sidemenu -->
    <transition name="sidebar">
      <div
        v-if="config.sidebar"
        class="sidebar"
        style="margin-top: 36px;"
      >
        <div class="list-group main-menu my-4">
          <router-link
            class="list-group-item list-group-item-action"
            to="/home"
          >
            <i class="home icon" /> Home
          </router-link>
          <router-link
            class="list-group-item list-group-item-action"
            to="/magazin"
          >
            <i class="newspaper icon" /> Magazin
          </router-link>
          <router-link
            class="list-group-item list-group-item-action"
            to="/about"
          >
            <i class="users icon" /> Über Wurstsalat
          </router-link>
          <router-link
            class="list-group-item list-group-item-action"
            to="/features"
          >
            <i class="fire icon" /> Features
          </router-link>
          <a
            class="list-group-item list-group-item-action"
            href="https://www.wurstsalat.shop"
            target="_blank"
          ><i class="cart icon" /> Wurstsalat Shop</a>
          <div class="pad-8">
            <div v-if="auth.isAuth">
              <router-link
                to="/wall"
                class="ui primary button fluid btn-color-1"
              >
                <img
                  class="ui avatar image"
                  :src="config.projectURL + 'assets/images/user/' + user.profile_img"
                >
                Community <i class="arrow right icon" />
              </router-link>
            </div>
            <div v-else>
              <router-link
                to="/login"
                class="ui primary button fluid btn-color-1"
              >
                Login
              </router-link>
              <div class="ui divider horizontal">
                Oder
              </div>
              <router-link
                to="/login/register"
                class="ui primary button fluid btn-color-2"
              >
                Registrieren
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </nav>
  <div id="publicSpacer" />
</template>

<script>
import { navbarSemantic } from '@/modules/adminnavbar'
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: 'Publicnavbar',
  setup() {
    const auth = authStore()
    const user = userStore()
    const config = configStore()

    return {auth, user, config}
  },
  data () {
    return {
    }
  },
  computed: {
    logo () {
      return this.config.darkMode ? 'assets/images/dark-wurstsalat-beta-pride.png' : 'assets/images/wurstsalat-beta-pride.png'
    }
  },
  mounted () {
    navbarSemantic()
  }
}
</script>

<style scoped>
#publicSpacer {
  padding-top: 27px;
}
@media (max-width: 992px) {
  #publicSpacer {
    padding-top: 36px;
  }
}
#MainMenu{
  border-radius: 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: #fbfbfb;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
#MainMenu .grid-16{
  width: 100%;
}
#MainMenu h1{
  font-size: 28px;
  font-family: 'Poppins';
  color: rgba(0,0,0,0.6);
}
#MainMenuLogo{
  max-width: 180px;
  padding: 16px;
}
#MainMenu ul{
  margin-top: 24px;
  margin-bottom: 0!important;
}
#MainMenu ul li{
  list-style: none;
  display: inline-block;
}
#MainMenu ul li a{
  text-decoration: none;
  padding: 24px 16px;
  color: rgba(0,0,0,0.6);
  font-size: 1.1em;
  font-weight: 600;
  transition: 0.3s;
}
#MainMenu ul li a:hover{
  color: #390055;
}
#MainMenu ul li#MobileMenu{
  display: none;
  font-size: 1.5em;
  padding: 24px 16px;
  cursor: pointer;
}
@media (max-width: 992px){
  #MainMenu #MainMenuCTA{
    display: none;
  }
  #MainMenu ul{
    text-align: right;
    margin-top: 0;
  }
  #MainMenu ul li{
    display: none;
  }
  #MainMenu ul li#MobileMenu{
    display: inline-block;
  }
}
/* Darkmode */
.dark #MainMenu{background-color: #1B1C1D!important;}
.dark #MainMenu ul li a{color: rgba(255,255,255,0.6);}
.dark #MainMenu ul li a:hover{color: rgba(255,255,255,1);}
</style>
