/*let reportType

export function report (type, id, profileID = id, wallID = id) {
  const reportMain = $('#reportMain')
  const reportMainMenu = $('#reportMainMenu')
  const reportSub = $('#reportSub')
  const reportSubMenu = $('#reportSubMenu')

  reportMain.dropdown('restore default text')
  reportSub.dropdown('restore default text')

  reportMainMenu.empty()
  reportSubMenu.empty()

  switch (type) {
    case 'u':
      type = 'profile'
      break

    case 'ch':
      type = 'chat'
      break

    case 'p':
      type = 'wall'
      break

    case 'c':
      type = 'comment'
      break

    case 's':
      type = 'story'
      break
  }

  const reportType = type
  $('#reportedID').val(id)
  $('#reportedType').val(type)
  $('#reportedProfileID').val(profileID)
  $('#reportedSpecialID').val(wallID)
  $('#reportRainbow').show()

  $.post(projectURL + 'report/getCategories', { SessionID: sessionStorage.getItem('SessionID'), postkey: sessionStorage.getItem('postkey') }, function (msg, status, xhr) {
    if (xhr.status === 250) {
      try {
        const data = JSON.parse(msg)
        for (const key in data) {
          if (data[key][reportType] === '1') {
            reportMainMenu.append('<div class="item" data-value="' + data[key].id + '">' + data[key].title + '</div>')
          }
        }
        reportMain.show()
        $('#reportRainbow').hide()
      } catch (e) {}
    } else if (xhr.status === 299) {
      cookieLogin().then(function () { location.reload() }).catch(openLogin)
    } else {
      location.reload()
    }
  })

  reportMain.dropdown('setting', 'onChange', function (val) {
    reportSubMenu.empty()
    reportSub.dropdown('restore default text')
    $.post(projectURL + 'report/getReasons', { SessionID: sessionStorage.getItem('SessionID'), postkey: sessionStorage.getItem('postkey'), reportCat: val }, function (msg, status, xhr) {
      if (xhr.status === 250) {
        $('#reportSend').prop('disabled', true)
        $('#reportDescription').hide()
        $('#reportBlock').hide()
        try {
          const data = JSON.parse(msg)
          for (const key in data) {
            if (data[key][reportType] === '1') {
              reportSubMenu.append('<div class="item" data-value="' + data[key].value + '">' + data[key].title + '</div>')
            }
          }
          reportSub.show()
        } catch (e) {}
      } else if (xhr.status === 299) {
        cookieLogin().then(function () {
          location.reload()
        }).catch(openLogin)
      } else {
        location.reload()
      }
    })
  })

  reportSub.dropdown('setting', 'onChange', function (val) {
    if (val === 'Nervt') {
      $('#reportSend').prop('disabled', true)
      $('#reportBlock').show()
      $('#reportDescription').hide()
    } else if (val !== '') {
      $('#reportSend').prop('disabled', false)
      $('#reportBlock').hide()
      $('#reportDescription').show()
    }
    $('#reportedCategory').val(val)
  })

  $('#reportedReason').val('')
  $('#reportedCategory').val('')
  $('#reportSend').prop('disabled', true)
  $('#reportDescription').hide()
  $('#reportBlock').hide()
  reportMain.hide()
  reportSub.hide()
  $('#ReportModal.ui.modal').modal('show')
}

export function sendReport (e = null) {
  if (e != null)e.preventDefault()
  $.post(projectURL + 'report', {
    postkey: sessionStorage.getItem('postkey'),
    SessionID: sessionStorage.getItem('SessionID'),
    reportedID: $('#reportedID').val(),
    reportedProfileID: $('#reportedProfileID').val(),
    reportedType: $('#reportedType').val(),
    reportedCategory: $('#reportedCategory').val(),
    reportedReason: $('#reportedReason').val(),
    reportedSpecialID: $('#reportedSpecialID').val()
  }, function () {
    $('#reportOk').modal('show')
  })
    .always(function () {
      $('#ReportModal').modal('hide')
      $('#reportForm').trigger('reset')
      $('#reportMain').dropdown('clear')
      $('#reportMainMenu').empty()
      $('#reportSub').dropdown('clear')
      $('#reportSubMenu').empty()
      $('#reportedSpecialID').empty()
    })
}*/
//window.report = report
//window.sendReport = sendReport

export function findGetParameter (parameterName) {
    var result = null
    var tmp = []
    location.search
        .substr(1)
        .split('&')
        .forEach(function (item) {
            tmp = item.split('=')
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
        })
    return result
}

export function getCookie (cname) {
    var name = cname + '='
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(';')
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return null
}

export function setCookie (cName, cValue, expDays = 30) {
    const date = new Date()
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000))
    const expires = 'expires=' + date.toUTCString()
    document.cookie = cName + '=' + cValue + '; ' + expires + '; path=/'
}

export function urlify (inputText) {
    var replacedText, replacePattern1, replacePattern2, replacePattern3
    if (inputText.includes('wurstsalat.app/')) {
        replacePattern1 = /(http[s]?:\/\/)?([^/\s]+\/)(.*)/gim
        replacedText = inputText.replace(replacePattern1, '<a style="font-weight: bold;" onclick="window.wurstsalat.$router.push(\'/$3\')">$2$3</a>')
        return replacedText
    }

    // URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim
    replacedText = inputText.replace(replacePattern1, '<a style="font-weight: bold;" onclick="window.wurstsalat.$router.push(\'/redirect?l=$1\')">$1</a>')

    // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim
    replacedText = replacedText.replace(replacePattern2, '$1<a style="font-weight: bold;" onclick="window.wurstsalat.$router.push(\'/redirect?l=$2\')">$2</a>')

    return replacedText
}

export function nl2br (str, replaceMode = false, isXhtml = false) {
    const breakTag = (isXhtml) ? '<br />' : '<br>'
    const replaceStr = (replaceMode) ? '$1' + breakTag : '$1' + breakTag + '$2'
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr)
}

export function semantic () {
    (function ($) {
        $.fn.visible = function (partial) {
            var $t = $(this)
            var $w = $(window)
            var viewTop = $w.scrollTop()
            var viewBottom = viewTop + $w.height()
            var _top = $t.offset().top
            var _bottom = _top + $t.height()
            var compareTop = partial === true ? _bottom : _top
            var compareBottom = partial === true ? _top : _bottom

            return ((compareBottom <= viewBottom) && (compareTop >= viewTop))
        }
    })(jQuery)

    /* header height and main container padding top fixed header */
    if ($('.header').hasClass('fixed-top') === true) {
        if ($('.main-container > div:first-child').hasClass('banner-hero') === true) {
            $('.main-container').css('padding-top', '0')
        } else {
            setTimeout(function () {
                $('.main-container').css('padding-top', $('.header').outerHeight())
            }, 500)
        }
    } else {
        if ($('.main-container > div:first-child').hasClass('banner-hero') === true) {
            $('.main-container').css('padding-top', '0')
        } else {
            $('.main-container').css('padding-top', '15px')
        }
    }

    /* header active on scroll more than 50 px */
    if ($('.footer-tabs').length > 0) {
        $('.main-container').css({
            'padding-bottom': $('.footer-tabs').outerHeight()
        })
        $('.footer').css({
            'padding-bottom': $('.footer-tabs').outerHeight() + 25
        })
    } else {
        $('.footer').css({
            'padding-bottom': 15
        })
    }

    /* header active on scroll more than 50 px */
    if ($(this).scrollTop() >= 30) {
        $('.header').addClass('active')
        $('.footer-spaces').addClass('active')
    } else {
        $('.header').removeClass('active')
        $('.footer-spaces').removeClass('active')
    }

    /* sidemenu close */
    $('.main-container').on('click', function () {
        if ($('.header .navbar-collapse.collapse').hasClass('show') === true) {
            $('.header .navbar-collapse.collapse').removeClass('show')
        }
        if ($('.sidebar-right').hasClass('active') === true) {
            $('.sidebar-right').removeClass('active')
            $('.colorsettings').removeClass('active')
        }
        if ($('.search').hasClass('active') === true) {
            $('.search').slideUp().removeClass('active')
        }
        if ($('body').hasClass('sidemenu-open') === true) {
            $('body').removeClass('sidemenu-open')
        }
        if ($('body').hasClass('reveal-sidebar') === true) {
            $('body').removeClass('reveal-sidebar')
        }
    })
    $('.header, .footer').on('click', function () {
        if ($('body').hasClass('sidemenu-open') === true) {
            $('body').removeClass('sidemenu-open')
        }
        if ($('body').hasClass('reveal-sidebar') === true) {
            $('body').removeClass('reveal-sidebar')
        }
    })

    /* .search button click mobile device */
    $('.search-btn').on('click', function () {
        $('.search').slideDown().addClass('active')
    })

    /* Background */
    $('.background').each(function () {
        var imgpath = $(this).find('img')
        $(this).css('background-image', 'url(' + imgpath.attr('src') + ')')
        imgpath.hide()
    })

    /* Iframes components preview resizing for devices. */
    $('.device-selection button.btn').on('click', function () {
        if ($(this).hasClass('active') !== true) {
            var parentcurrent = $(this).parent().find('.btn.active').attr('data-class')
            var parentclass = $(this).attr('data-class')
            $(this).parent().find('.btn').removeClass('active')
            $(this).addClass('active').closest('.demo-view').find('.iframeselements').addClass(parentclass).removeClass(parentcurrent)
        } else {

        }
    })

    /* nav small btn expand collapse and sidemenu open close */
    if ($('.header .navbar').hasClass('navbar-expand-all') === true) {
        $('.main-container').on('click', function () {
            $('.header .navbar .navbar-collapse').removeClass('show')
        })
    } else {}

    /* login row */
    $('.login-row').css('min-height', ($(window).height() - 80))

    /* home page hover text demo */
    $('.hover-text span').on('mouseenter', function () {
        $('.demolive-wraper').slideDown()
        $('.close-demolive-wrapper').fadeIn()

        $('.fullscreen .demolive-wraper').on('mouseleave', function () {
            var thiswrap = $(this)
            if (thiswrap.closest('.fullscreen').hasClass('active') === true) {
                thiswrap.slideUp()
                thiswrap.closest('.fullscreen').find('.close-demolive-wrapper').fadeOut()
            }
        })
    })

    $('.fullscreen.active, .close-demolive-wrapper').on('click', function () {
        $('.demolive-wraper').slideUp()
        $('.close-demolive-wrapper').fadeOut()
    })

    /* Fullscreen btn and area */
    $('.fullscreen-btn').on('click', function () {
        var fullscreenwrap = $(this).closest('.fullscreen')
        fullscreenwrap.toggleClass('active')
        $('body').toggleClass('overflow-hidden')
        if ($('.fullscreen').hasClass('active') !== true) {
            $('.demolive-wraper').slideDown()
        }
        if (!document.fullscreenElement && // alternative standard method
            !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) { // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen()
            } else if (document.documentElement.msRequestFullscreen) {
                document.documentElement.msRequestFullscreen()
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen()
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen()
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen()
            }
        }
    })

    /* scroll to top  button  */
    $(window).scroll(function () {
        if ($(this).scrollTop() > 200) {
            $('.scrollup').show()
            setTimeout(function () {
                $('.scrollup').addClass('active')
            }, 500)
        } else {
            $('.scrollup').hide()
            setTimeout(function () {
                $('.scrollup').removeClass('active')
            }, 500)
        }
    })

    $('.scrollup').click(function () {
        $('html, body').animate({
            scrollTop: 0
        }, 600)
        return false
    })

    if ($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
        $('.scrollup').addClass('atbottom')
    } else {
        $('.scrollup').removeClass('atbottom')
    }

    /* sidebar right color scheme */
    $('.colorsettings').on('click', function () {
        $(this).toggleClass('active')
        $('.sidebar-right').toggleClass('active')
    })

    /* loading button load more */
    $('.loading-btn ').on('click', function () {
        var thisbtn = $(this)
        thisbtn.addClass('active')

        setTimeout(function () {
            thisbtn.removeClass('active').blur()
        }, 3000)
    })

    /* smooth scroll */
    $(document).on('click', '.smoothscroll', function (event) {
        event.preventDefault()

        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 750)
    })

    $(window).on('scroll', function () {
        /* header active on scroll more than 50 px */
        if ($(this).scrollTop() >= 30 && $('.header').hasClass('fixed-top') === true) {
            $('.header').addClass('active')
            $('.footer-spaces').addClass('active')
        } else {
            $('.header').removeClass('active')
            $('.footer-spaces').removeClass('active')
        }

        /* scroll to top  button  hide when at bottom of page */
        if ($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
            $('.scrollup').addClass('atbottom')
        } else {
            $('.scrollup').removeClass('atbottom')
        }
    })

    $(window).on('resize', function () {
        /* login row */
        $('.login-row').css('min-height', ($(window).height() - 80))
    })
    $('.dropdown').dropdown()
}

export function loadError () {
    const urlParams = new URLSearchParams(window.location.search)
    const searchId = urlParams.get('seid')
    if (searchId && searchId > 0) {
        $('#SEModal.ui.modal').modal('show')
    }
}

export function decode (obj) {
    try {
        return decodeURIComponent(escape(obj))
    } catch (e) {
        return obj
    }
}

export function makeid (length) {
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength))
    }
    return result
}

export function encrypt (s, cipherKey) {
    const iv = crypto.randomBytes(16)
    const cipher = crypto.createCipheriv('aes-128-cbc', Buffer.from(cipherKey), iv)
    let encrypted = cipher.update(s)

    encrypted = Buffer.concat([encrypted, cipher.final()])

    return iv.toString('hex') + ':' + encrypted.toString('hex')
}

export function decrypt (s, cipherKey) {
    const textParts = s.split(':')
    const iv = Buffer.from(textParts.shift(), 'hex')
    const encryptedText = Buffer.from(textParts.join(':'), 'hex')
    const decipher = crypto.createDecipheriv('aes-128-cbc', Buffer.from(cipherKey), iv)
    let decrypted = decipher.update(encryptedText)

    decrypted = Buffer.concat([decrypted, decipher.final()])

    return decrypted.toString()
}

export function clickElement (elem) {
    // Create our event (with options)
    const evt = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
    })
    // If cancelled, don't dispatch our event
    const canceled = !elem.dispatchEvent(evt)
}

export function playSound (src, silent = true, vibrate = false) {
    if (typeof cordova !== 'undefined') {
        try {
            window.sound.stop()
            window.sound.release()
        } catch (e) {}
        window.sound = new Media(src)
        window.sound.setVolume(localStorage.getItem('volume') / 100)
        if (silent) {
            window.sound.play({ playAudioWhenScreenIsLocked: false })
        } else {
            window.sound.play({ playAudioWhenScreenIsLocked: true })
        }

        if (vibrate && localStorage.getItem('vibrate') === '1') navigator.vibrate(50)
    } else {
        try {
            window.sound.pause()
        } catch (e) {}
        window.sound = new Audio(src)
        window.sound.volume = localStorage.getItem('volume') / 100
        window.sound.play()
    }
}