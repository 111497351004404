<template>
  <div
    id="ReportModal"
    class="ui tiny modal"
  >
    <div
      id="reportRainbow"
      class="rainbowloader"
    >
      <div class="loader-inner">
        <div class="loader-line-wrap">
          <div class="loader-line" />
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line" />
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line" />
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line" />
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line" />
        </div>
      </div>
    </div>
    <i class="close icon report" />
    <div class="header">
      <i class="flag icon" /> Melden
    </div>
    <div class="content">
      <form
        id="reportForm"
        class="ui form"
        method="post"
        onsubmit="sendReport()"
      >
        <input
          id="reportedID"
          type="hidden"
          value=""
          name="reportedID"
        >
        <input
          id="reportedType"
          type="hidden"
          value=""
          name="reportedType"
        >
        <input
          id="reportedCategory"
          type="hidden"
          value=""
          name="reportedCategory"
        >
        <input
          id="reportedSpecialID"
          type="hidden"
          value=""
          name="reportedSpecialID"
        >
        <div class="field">
          <div
            id="reportMain"
            class="ui fluid dropdown"
          >
            <span class="text">Kategorie</span>
            <i class="dropdown icon" />
            <div
              id="reportMainMenu"
              class="menu"
            />
          </div>
          <div class="pad-8" />
          <div
            id="reportSub"
            class="ui fluid dropdown"
          >
            <span class="text">Grund des Meldens</span>
            <i class="dropdown icon" />
            <div
              id="reportSubMenu"
              class="menu"
            />
          </div>
        </div>
        <div
          id="reportDescription"
          class="field"
        >
          <label>Beschreibung</label>
          <div class="ui input">
            <textarea
              id="reportedReason"
              placeholder="Zusätzliche Bemerkung"
              name="reportedReason"
            />
          </div>
        </div>
        <div id="reportBlock">
          Sollte dich der User lediglich nerven, möchten wir dir hierfür unsere Blockier-Funktion empfehlen.
          Sobald du den Nutzer blockiert hast, wirst du keine Inhalte mehr von ihm sehen können.
          Ebenso bleiben auch deine Inhalte vor ihm versteckt.
        </div>
      </form>
    </div>
    <div class="actions">
      <div
        class="ui button"
        onclick="$('#ReportModal.ui.modal').modal('hide');"
      >
        Abbrechen
      </div>
      <button
        id="reportSend"
        class="ui btn-color-3 right labeled icon button"
        onClick="sendReport();"
      >
        Melden
        <i class="exclamation icon" />
      </button>
    </div>
  </div>
  <div
    id="reportOk"
    class="ui mini modal"
  >
    <i class="close icon" />
    <div class="header">
      Danke für dein Report
    </div>
    <div class="content">
      <p>Wir werden uns um dein Anliegen schnellstmöglich kümmern.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Report'
}
</script>

<style scoped>

</style>
